.dashboardOptionsBorder{
    border: 1px solid #E0E0E0;
    padding: 0.4rem 0.7rem;
}
.titlesDashboard{
    margin-top: 0.7rem;
}

.radio-options-profile{
    margin-top: 1.1rem;
    margin-right: 0.4rem;
}
.space-for-radios-table-length , .checkboxes-right-space-options{
    margin-right: 0.4rem;
}
.grid-systheme-for-radios-options-table-length{
    margin-top: 0.7rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row: 1fr 1fr ;
    row-gap: 1.1rem;
    width: 60%;
}
.checkboxes-options-affichage{
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
}
/* .dashboardOptionsBorder > div > label > input:checked{
    accent-color: black !important;
} */