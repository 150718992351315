.Option-Affichage{
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: scroll;
    height: calc(100% - 3rem);
}
.setUpTheWith{
    width: 80%;
}
.darkSpaceOptions{
    background-color: black;
    opacity: 0.2;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.themeBarOptions{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 450px;
    overflow-y: scroll;
    background-color: white;
    z-index: 700;
    animation: inn ease-in-out 0.5s;
}
@media (max-width: 650px) {
    .themeBarOptions{
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        width: 100% !important;
        height: 100% !important;
        z-index: 5000 !important;
    }
}

.themeBarOptions > .part1 , .themeBarOptions > .part2 , .themeBarOptions > .part3 {
    margin-inline: 1rem;
}

.themeBarOptions > .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.themeBarOptions > .part1 > .Blue-Title , .themeBarOptions > .part2 > .Blue-Title , .themeBarOptions > .part3 > .Blue-Title{
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}

.part2 > .sizedOption > label {
    font-family: 'Roboto';
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.4px;
    text-align: left;
    
}

.sizedOption{
    width:40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ColorsSets{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row: 1fr 1fr ;
    row-gap: 1.5rem;
    column-gap: 2.5rem;
    width: 100%;
}

.ThemeLayoutSelectionPart{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row: 1fr 1fr 1fr;
    row-gap: 1.5rem;
    column-gap: 1.5rem;
    width: 100%;
    & .imgLayout{
        width: 180px;
        height: 120px;
        cursor: pointer;
        & input{
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            height: 100%;
            width: 100%;
        }
    }
    & .clicked{
        border: 2px solid #1890FF;
    }
    & .selectedLayout{
        border: 2px solid blue;
    }
}

.bottomBoxColorExpample {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 25px;
}


.ColorsSets  {
    .c1{
        background-color: #F8FBFF;
        .checked{
            color: #1976D2;
        }
    }
    .c2{
        background-color: #cee3e7;
        .checked{
            color: #002d3d;
        }
    }
    .c3{
        background-color: #426c7a;
        .checked{
            color: #b4c7e5;
        }
    }
    .c4{
        background-color: #8499e4;
        .checked{
            color: #ffcd38;
        }
    }
    .c5{
        background-color: #11776c;
        .checked{
            color: #8ee5dc;
        }
    }
    .c6{
        background-color: #aaaaaa;
        .checked{
            color: #F32B84;
        }
    }
}

.ColorsSets > .c1 > .boxing {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.04);
    position: relative;
    height: 80px;
    .checked{
        position: absolute;
        top: 0;
        right: 0;
        
    }
    .bottomBoxColorExpample{
        background-color: #1976D2;
    }
    .topBoxColorPalette{
        position: relative;
        height: 55px;
        .paletteColor{
            color: #1976D2;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            font-size: 3rem;
        }
    }
    
}
.ColorsSets > .c2 > .boxing {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.04);
    position: relative;
    height: 80px;
    .checked{
        position: absolute;
        top: 0;
        right: 0;
        
    }
    .bottomBoxColorExpample{
        background-color: #002d3d;
    }
    .topBoxColorPalette{
        position: relative;
        height: 55px;
        .paletteColor{
            color: #002d3d;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            font-size: 3rem;
        }
    }
}
.ColorsSets > .c3 > .boxing {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.04);
    position: relative;
    height: 80px;
    .checked{
        position: absolute;
        top: 0;
        right: 0;
        
    }
    .bottomBoxColorExpample{
        background-color: #b4c7e5;
    }
    .topBoxColorPalette{
        position: relative;
        height: 55px;
        .paletteColor{
            color: #b4c7e5;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            font-size: 3rem;
        }
    }
}
.ColorsSets > .c4 > .boxing {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.04);
    position: relative;
    height: 80px;
    .checked{
        position: absolute;
        top: 0;
        right: 0;
        
    }
    .bottomBoxColorExpample{
        background-color: #ffcd38;
    }
    .topBoxColorPalette{
        position: relative;
        height: 55px;
        .paletteColor{
            color: #ffcd38;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            font-size: 3rem;
        }
    }
}
.ColorsSets > .c5 > .boxing {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.04);
    position: relative;
    height: 80px;
    .checked{
        position: absolute;
        top: 0;
        right: 0;
        
    }
    .bottomBoxColorExpample{
        background-color: #8ee5dc;
    }
    .topBoxColorPalette{
        position: relative;
        height: 55px;
        .paletteColor{
            color: #8ee5dc;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            font-size: 3rem;
        }
    }
}
.ColorsSets > .c6 > .boxing {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.04);
    position: relative;
    height: 80px;
    .checked{
        position: absolute;
        top: 0;
        right: 0;
        
    }
    .bottomBoxColorExpample{
        background-color: #F32B84;
    }

    .topBoxColorPalette{
        position: relative;
        height: 55px;
        .paletteColor{
            color: #F32B84;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            font-size: 3rem;
        }
    }
}





@keyframes inn {
      0% {
        right: -30%;
      }
    
      100% {
        right: 0;
      }
}

.themeOptionsGlobal{
    height: 100%;
}


.imgLayout{
    position: relative;
}
