.divGlobalForgetPassword {
  width: 100%;
  height: 100vh;
  position: relative;

  .col-connexion-forget-pass,
  .col-connexion_recovery-pass {
    width: 30%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media screen and (max-width: 768px) {
      width: 50%;
    }
    @media screen and (max-width: 767px) {
      width: 80%;
    }
    @media screen and(min-width: 768px) and (max-width: 1300px) {
      width: 45%;
    }
  }
}
.col-connexion {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.span_connexion {
  font-family: "SF Pro Display";
  font-size: 20px;
  font-weight: normal;
}
.col_input input {
  font-family: "SF Pro Display";
  font-size: 14px;
  font-weight: normal;
}
.spn_chbx {
  font-family: "SF Pro Display";
  font-size: 14px;
  font-weight: normal;
}
.Connection-form-forget-pass {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 700px;
}

@media (min-width: 576px) and (max-width: 768px) {
  .Connection-form-forget-pass {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 320px;
  }
}
@media (min-width: 280px) and(max-width:576px) {
  .Connection-form-forget-pass {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 320px;
  }
}
