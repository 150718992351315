.divGlobalResetPassword {
  width: 100%;
  height: 100vh;
  position: relative;

  .col-connexion-forget-pass , .col-connexion_recovery-pass{
    width: 25%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

}
.col-connexion{
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
  transform: translate(-50%, -50%);
  @media screen and (max-width: 768px){
    width: 50%;
  }
  @media screen and (max-width: 767px){
    width: 80%;
  }
}
.span_connexion{
    font-family: "SF Pro Display";
    font-size: 20px;
    font-weight: normal;
}
.col_input input{
  font-family: "SF Pro Display";
  font-size: 14px;
  font-weight: normal;
}
.spn_chbx{
  font-family: "SF Pro Display";
  font-size: 14px;
  font-weight: normal;
}