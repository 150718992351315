
// :root {
//   --primary-color: #302AE6;
//   --secondary-color: #536390;
//   --font-color: #424242;
//   --bg-color: #fff;
//   --heading-color: #292922;
// }

// [data-theme="dark"] {
//   --primary-color: #9A97F3;
//   --secondary-color: #818cab;
//   --font-color: #e1e1ff;
//   --bg-color: #161625;
//   --heading-color: #818cab;
// }

// body {
//   background-color: var(--bg-color) !important;
//   color: var(--font-color) !important;
// }

// .globalDashboard{
//   background-color: var(--bg-color);
// }
.ActionsBooksMediaVideo{
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Define 3 columns */
  grid-auto-rows: 1fr;

}
@media screen  and (max-width: 650px){
  .ActionsBooksMediaVideo{
    display: grid;
  grid-template-columns: repeat(2, 1fr); /* Define 3 columns */
  grid-auto-rows: 1fr;
  }
}
@media screen  and (max-width: 500px){
  .ActionsBooksMediaVideo{
    display: grid;
  grid-template-columns: repeat(1, 1fr); /* Define 3 columns */
  grid-auto-rows: 1fr;
  }
}
.BookGuideComponent{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  // background: #1890ff;
  // width: 50%;
  // height: 80%;
  // box-shadow: 0 0 100000000000px rgba(0, 0, 0, 0.5);
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
  max-width: 1000px !important;
}

.childrenSafe{
  padding: 0px 24px;
}
.globalDashboard{
  height: 100vh;
  width: 100vw;
  display: flex;
  position: relative;
}
.boxed {
  transform: scaleX(0.7);
}
@media screen  and (max-width: 767px){
  .boxed {
    transform: scaleX(1);
  }
}
@import '~antd/dist/antd.css';
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
//#root{
//  &.lightMode{
//    @import '~antd/dist/antd.css';
//  }
//  &.darkMode{
//    @import "~antd/dist/antd.dark.css";
//  }
//}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  display: flex;
}

.Default_Page{
  position: absolute;
  left: 50%;
  top:50%;
  transform: translate(-50% , -50%);
}
.Default_Page span{
  font-size:80px;
  font-weight:800;
  text-align:center;
  font-family: 'Roboto', sans-serif;
}
.Default_Page h3{
  font-size:25px;
  text-align:center;
  font-family: 'Roboto', sans-serif;
  margin-top:-40px;
}
.Default_Page p{
  text-align:center;
  font-family: 'Roboto', sans-serif;
  font-size:12px;
}

.menu {
  .ant-menu-horizontal {
    & > .ant-menu-submenu {
      float: right;
    }
    border: none;
  }
  box-shadow: #e4ecef;
  position: relative;
  .ant-menu-submenu-title {
    width: 64px;
    height: 64px;
    text-align: center;
    padding-top: 8px;
  }
}
.ant-layout-sider::-webkit-scrollbar{
  width: 4px !important;
  height: 4px;
}
.ant-layout-sider::-webkit-scrollbar-track{
  background-color: #FFFFFF !important;
  z-index: 999;
  border: none !important;
  box-shadow: none !important;
}
.ant-layout-sider-dark::-webkit-scrollbar-track{
  background-color: #011529 !important;
  z-index: 999;
  border: none !important;
  box-shadow: none !important;
}
.ant-layout-sider::-webkit-scrollbar-thumb{
  background-color: rgba(0, 0, 0, 0.25);    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
}
.ant-layout-sider-dark::-webkit-scrollbar-thumb{
  background-color: rgba(255, 255, 255, 0.25);    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover svg{
  color: #1890ff !important;
}

.trigger {
  margin-left: 16px;
  margin-right: 16px;
  align-self: center;
  font-size: 16.25px !important;
}
.menu-toggle{
  float: left;
  width: 100%;
  align-self: center;
  display: flex;
  height: 100%;
}
.ant-menu-inline-collapsed{
  font-size: 0;
}
.menu-logo {
  background-image: url('./assets/icon_dam.png');
  background-repeat: no-repeat;
  background-position: center;
  height: 65px;
  background-size: 40%;
  color: #ffffff;
  width: 70px;
}
.logo-content{
  display: flex;
  flex-direction: row;
  height: 63px;
}
.svg{
  margin-right: 10px;
  font-size: 20px!important;
  margin-bottom: 0.3rem;
}
.switch {
  position: relative;
  display: inline-block;
  height: 25px;
  width: 75px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 27px;
  width: 47px;
}

.slider:before {
  font-size : 20px;
  position: absolute;
  color: #000000;
  content: "";
  height:23px;
  width: 23px;
  left: 2px;
  top: 0;
  bottom: 1px;
  margin: auto 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background: white url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAfJJREFUOBGFUz1IHEEUnje7d1whRDxIe2txaBeEYGV2Z70jhylSqF1CEm3ExiJgbyWCkCpdIBHsRJsEAgd33o+dCGIZJLCWCSqmCIg3O+P37jzR9dSBuZv3vp99b/atEA+scaVWeD9AEU4XXFLKlZ63kPe8s99RdBz6/mtr7WfsscFcbj86Ovr1UqnhnOe9fe95e/UoMqylrkGxWHyiLy7O2jHRIYCnwtoOTmStEH8R5xl30+n+SqXyj8+SfxKrJYk20kTPtxuNft585hx4rQS3U0GhUMjGcTwtjFklx3lRq9UOkkSOwzB8ZuN4R0i56DjOZrVaPSEVBN9R5yv06kgpZ7br9bVe4m5uPAhmjLVfiShGWz8levjBYkF0/JiYTdDSN+a2HwitROIL3NZRxW73KY/9M5c1rO1corW9LvNeH5ROeCNtjauUmrPGvIHryb2KBADuKEyy0O5IMmYCPWn0lMXwzCa4d0LmMJc1rIWZEKVSaaB1fj6F0frkWutXms39O0okir4/oomaqP1jKpPZKpfLp20DJl9PIjsLsSpddw3TdniF5Y3WH1D2Inp3b06iy4RbCwSUNxlrPR8GQedbwTuH6R8W3+IiuL79Ma3/Y1wXnFRqqNZoDAN4B7yPN585xxhzmJs06hmjgmXePcGr5CUiGswYwvc9zAAAAABJRU5ErkJggg==');
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 65px;
}

input:checked + .slider {
  background-color: #b6b6b6;
  border-radius: 27px;
  height: 25px;
}

input:focus + .slider {
  box-shadow: 0 0 1px #b6b6b6;
}

input:checked + .slider:before {
  font-size : 20px;
  border-radius: 27px;
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
  background: white url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAh1JREFUOBGFUkFoU0EQ3dkYayEgIm3REkwolqInUW+Bv/ma2BIEL4IUoZeiglUQTwoeetJ7vfagh1wVIShJMf9DT7UVBFMKUX6LUKQVPBhs+pv89c22W3qoycLs7O6892Z2dkkcGDeVSvzS+oHQekoQva36/v0D4UOXR+xpXqmRTa3fgDyyd3bOxjp5I5DP55M7zaYPYL8FkxCf7bqTlxwMt7eLGmQiWoIV+UxrvcG+25Cu44wBnUHGUEg5AeI6k6SUfd3IBhcJMc4LknKmWq3WJNEC7yE0yr7b4CtkDEhr7oHoTSTm8AINCJzPZrPXTKzDJFH6KY7LePwL+1Kp9BvpX/JaRNEr13UHzfo/k0SmvyYWhictJiXlNDcUsQHdbi91qoSU43xCxkvo2qTnebNWJJfLnW6F4TuIXOQzCNZgH6Io2oQfwKtdQPXLfIX3hqT1BMDY7o5KpbJ+higD8HPbE5AfI/oCuEdIqmA3iO8YtdvfsDmGF3j40fdnrIj1hULhxFajcRVZL8OSwN7iGMSfxoIg+JNOpXAurmBSQ+n092B19asls6/X681gbW357PBwDcnu4CgJ9kof0aQpmUvPKvUayrcNkagCwCw+02IsFttqtVpp0vo6cPdgx0HaiEvplD1vZf/OLOIq9QRVPOPrGKHDJqL5oz094+Vy+QeH9wUslnuCp7sLIf7iQ6ikF7GfsHn8uiJ6tNv0PcI/TyvjgPaHFDUAAAAASUVORK5CYII=');
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 65px;
}
.switch-mode{
  padding: 1.3em 0;
}
.flag-menu-fr {
  margin-right: 12px;
  background-repeat: no-repeat;
  display: inline-block;
  width: 16px;
  height: 11px;
  background:url('./assets/fr.png');
  background-size: 100%;
}
.flag-menu-en {
  margin-right: 12px;
  background-repeat: no-repeat;
  display: inline-block;
  width: 16px;
  height: 11px;
  background: url('./assets/en.png');
  background-size: 100%;
}
.menu-logo-icon{
  background-image: url('./assets/typo_dam.png');
  background-repeat: no-repeat;
  background-position: center;
  height: 65px;
  background-size: 70%;
  width: 50%;
  margin-left: -30px;
  color: #ffffff;
}
.ant-pagination-options{
  position: absolute;
  left: 0;
}
.row-event{
  height: 58px;
  background-color: rgba(0, 0, 0, 0.04);
  align-items: center;
  padding: 0 2em;
}
.text-event{
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.85);
}
.col-search{
  width: 60%;
}
.input-search{
  height: 40px;
  padding: 0!important;
}
.row-action{
  justify-content: space-between;
  margin: 1em 0;
}
.calendar{
  width: 205px;
  height: 40px;
  border-radius: 2px;
  border: solid 1px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.emp{
  justify-content: space-between;
  height: 58px;
  background-color: rgba(0, 0, 0, 0.04);
  align-items: center;
  padding: 0 1.5em;
}
.title{
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.85);
  margin-left: 10px;
}
.label{
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 0.5em;
}
.require{
  color: #ff4d4f;
  margin-left: 2px;
}
.input-form{
  display: flex;
  flex-direction: column;
}
.input-form-date{
  margin-right: 2em;
  display: flex;
  flex-direction: column;
}
.row-tabPane{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.date-picker{
  display: flex;
  flex-direction: row;
}
.bloc-form{
  width: 49%;
  border-radius: 2px;
  border: solid 1px rgba(0, 0, 0, 0.15);
  padding: 2em;
}
.text{
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  margin-left: 10px;
}
.colTabPane{
  width: 50%;
  border-radius: 2px;
  border: solid 1px rgba(0, 0, 0, 0.15);
  padding: 2em;
}
@font-face {
  font-family: 'icons';
  src:  url('./assets/fonts/icons.eot?c1rwf');
  src:  url('./assets/fonts/icons.eot?c1rwf#iefix') format('embedded-opentype'),
  url('./assets/fonts/icons.ttf?c1rwf') format('truetype'),
  url('./assets/fonts/icons.woff?c1rwf') format('woff'),
  url('./assets/fonts/icons.svg?c1rwf#icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: white;
  // font-size: 24px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-account-alert:before {
  font-size : 20px;
  color: #000000;
  content: "\e600"; }

.icon-account-box-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e601"; }

.icon-account-box {
  background-image: url('./assets/group.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  height: 16px;
  width: 16px; 
  margin-left: 2px;
  
}

.icon-account-check:before {
  font-size : 20px;
  color: #000000;
  content: "\e603"; }

.icon-account-circle:before {
  font-size : 20px;
  color: #000000;
  content: "\e604"; }

.icon-account-key:before {
  font-size : 20px;
  color: #000000;
  content: "\e605"; }

.icon-account-location:before {
  font-size : 20px;
  color: #000000;
  content: "\e606"; }

.icon-account-minus:before {
  font-size : 20px;
  color: #000000;
  content: "\e607"; }

.icon-account-multiple-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e608"; }

.icon-account-multiple-plus:before {
  font-size : 20px;
  color: #000000;
  content: "\e609"; }

.icon-account-multiple:before {
  font-size : 20px;
  color: #000000;
  content: "\e60a"; }


  .icon-account-multiplewhite {
    background-image: url('./assets/goupe.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    height: 16px;
    width: 16px; 
    margin-left: 2px;
    
  }









.icon-account-network:before {
  font-size : 20px;
  color: #000000;
  content: "\e60b"; }

.icon-account-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e60c"; }

.icon-account-plus:before {
  font-size : 20px;
  color: #000000;
  content: "\e60d"; }

.icon-account-remove:before {
  font-size : 20px;
  color: #000000;
  content: "\e60e"; }

.icon-account-search:before {
  font-size : 20px;
  color: #000000;
  content: "\e60f"; }

.icon-account-switch:before {
  font-size : 20px;
  color: #000000;
  content: "\e610"; }

.icon-account:before {
  font-size : 20px;
  color: #000000;
  content: "\e611"; }

.icon-airballoon:before {
  font-size : 20px;
  color: #000000;
  content: "\e612"; }

.icon-airplane-off:before {
  font-size : 20px;
  color: #000000;
  content: "\e613"; }

.icon-airplane:before {
  font-size : 20px;
  color: #000000;
  content: "\e614"; }

.icon-alarm-check:before {
  font-size : 20px;
  color: #000000;
  content: "\e615"; }

.icon-alarm-multiple:before {
  font-size : 20px;
  color: #000000;
  content: "\e616"; }

.icon-alarm-off:before {
  font-size : 20px;
  color: #000000;
  content: "\e617"; }

.icon-alarm-plus:before {
  font-size : 20px;
  color: #000000;
  content: "\e618"; }

.icon-alarm:before {
  font-size : 20px;
  color: #000000;
  content: "\e619"; }

.icon-album:before {
  font-size : 20px;
  color: #000000;
  content: "\e61a"; }

.icon-alert-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e61b"; }

.icon-alert-circle:before {
  font-size : 20px;
  color: #000000;
  content: "\e61c"; }

.icon-alert-octagon:before {
  font-size : 20px;
  color: #000000;
  content: "\e61d"; }

.icon-alert:before {
  font-size : 20px;
  color: #000000;
  content: "\e61e"; }

.icon-alpha:before {
  font-size : 20px;
  color: #000000;
  content: "\e61f"; }

.icon-alphabetical:before {
  font-size : 20px;
  color: #000000;
  content: "\e620"; }

.icon-amazon-clouddrive:before {
  font-size : 20px;
  color: #000000;
  content: "\e621"; }

.icon-amazon:before {
  font-size : 20px;
  color: #000000;
  content: "\e622"; }

.icon-ambulance:before {
  font-size : 20px;
  color: #000000;
  content: "\e623"; }

.icon-android-debug-bridge:before {
  font-size : 20px;
  color: #000000;
  content: "\e624"; }

.icon-android-studio:before {
  font-size : 20px;
  color: #000000;
  content: "\e625"; }

.icon-android:before {
  font-size : 20px;
  color: #000000;
  content: "\e626"; }

.icon-apple-finder:before {
  font-size : 20px;
  color: #000000;
  content: "\e627"; }

.icon-apple-ios:before {
  font-size : 20px;
  color: #000000;
  content: "\e628"; }

.icon-apple-mobileme:before {
  font-size : 20px;
  color: #000000;
  content: "\e629"; }

.icon-apple-safari:before {
  font-size : 20px;
  color: #000000;
  content: "\e62a"; }

.icon-apple:before {
  font-size : 20px;
  color: #000000;
  content: "\e62b"; }

.icon-appnet:before {
  font-size : 20px;
  color: #000000;
  content: "\e62c"; }

.icon-apps:before {
  font-size : 20px;
  color: #000000;
  content: "\e62d"; }

.icon-archive:before {
  font-size : 20px;
  color: #000000;
  content: "\e62e"; }

.icon-arrange-bring-forward:before {
  font-size : 20px;
  color: #000000;
  content: "\e62f"; }

.icon-arrange-bring-to-front:before {
  font-size : 20px;
  color: #000000;
  content: "\e630"; }

.icon-arrange-send-backward:before {
  font-size : 20px;
  color: #000000;
  content: "\e631"; }

.icon-arrange-send-to-back:before {
  font-size : 20px;
  color: #000000;
  content: "\e632"; }

.icon-arrow-all:before {
  font-size : 20px;
  color: #000000;
  content: "\e633"; }

.icon-arrow-bottom-left:before {
  font-size : 20px;
  color: #000000;
  content: "\e634"; }

.icon-arrow-bottom-right:before {
  font-size : 20px;
  color: #000000;
  content: "\e635"; }

.icon-arrow-collapse:before {
  font-size : 20px;
  color: #000000;
  content: "\e636"; }

.icon-arrow-down-bold-circle-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e637"; }

.icon-arrow-down-bold-circle:before {
  font-size : 20px;
  color: #000000;
  content: "\e638"; }

.icon-arrow-down-bold-hexagon-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e639"; }

.icon-arrow-down-bold:before {
  font-size : 20px;
  color: #000000;
  content: "\e63a"; }

.icon-arrow-down:before {
  font-size : 20px;
  color: #000000;
  content: "\e63b"; }

.icon-arrow-expand:before {
  font-size : 20px;
  color: #000000;
  content: "\e63c"; }

.icon-arrow-left-bold-circle-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e63d"; }

.icon-arrow-left-bold-circle:before {
  font-size : 20px;
  color: #000000;
  content: "\e63e"; }

.icon-arrow-left-bold-hexagon-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e63f"; }

.icon-arrow-left-bold:before {
  font-size : 20px;
  color: #000000;
  content: "\e640"; }

.icon-arrow-left:before {
  font-size : 20px;
  color: #000000;
  content: "\e641"; }

.icon-arrow-right-bold-circle-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e642"; }

.icon-arrow-right-bold-circle:before {
  font-size : 20px;
  color: #000000;
  content: "\e643"; }

.icon-arrow-right-bold-hexagon-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e644"; }

.icon-arrow-right-bold:before {
  font-size : 20px;
  color: #000000;
  content: "\e645"; }

.icon-arrow-right:before {
  font-size : 20px;
  color: #000000;
  content: "\e646"; }

.icon-arrow-top-left:before {
  font-size : 20px;
  color: #000000;
  content: "\e647"; }

.icon-arrow-top-right:before {
  font-size : 20px;
  color: #000000;
  content: "\e648"; }

.icon-arrow-up-bold-circle-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e649"; }

.icon-arrow-up-bold-circle:before {
  font-size : 20px;
  color: #000000;
  content: "\e64a"; }

.icon-arrow-up-bold-hexagon-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e64b"; }

.icon-arrow-up-bold:before {
  font-size : 20px;
  color: #000000;
  content: "\e64c"; }

.icon-arrow-up:before {
  font-size : 20px;
  color: #000000;
  content: "\e64d"; }

.icon-at:before {
  font-size : 20px;
  color: #000000;
  content: "\e64e"; }

.icon-attachment:before {
  font-size : 20px;
  color: #000000;
  content: "\e64f"; }

.icon-audiobook:before {
  font-size : 20px;
  color: #000000;
  content: "\e650"; }

.icon-auto-fix:before {
  font-size : 20px;
  color: #000000;
  content: "\e651"; }

.icon-auto-upload:before {
  font-size : 20px;
  color: #000000;
  content: "\e652"; }

.icon-baby:before {
  font-size : 20px;
  color: #000000;
  content: "\e653"; }

.icon-backburger:before {
  font-size : 20px;
  color: #000000;
  content: "\e654"; }

.icon-backup-restore:before {
  font-size : 20px;
  color: #000000;
  content: "\e655"; }

.icon-bank:before {
  font-size : 20px;
  color: #000000;
  content: "\e656"; }

.icon-barcode:before {
  font-size : 20px;
  color: #000000;
  content: "\e657"; }

.icon-barley:before {
  font-size : 20px;
  color: #000000;
  content: "\e658"; }

.icon-barrel:before {
  font-size : 20px;
  color: #000000;
  content: "\e659"; }

.icon-basecamp:before {
  font-size : 20px;
  color: #000000;
  content: "\e65a"; }

.icon-basket-fill:before {
  font-size : 20px;
  color: #000000;
  content: "\e65b"; }

.icon-basket-unfill:before {
  font-size : 20px;
  color: #000000;
  content: "\e65c"; }

.icon-basket:before {
  font-size : 20px;
  color: #000000;
  content: "\e65d"; }

.icon-battery-10:before {
  font-size : 20px;
  color: #000000;
  content: "\e65e"; }

.icon-battery-20:before {
  font-size : 20px;
  color: #000000;
  content: "\e65f"; }

.icon-battery-30:before {
  font-size : 20px;
  color: #000000;
  content: "\e660"; }

.icon-battery-40:before {
  font-size : 20px;
  color: #000000;
  content: "\e661"; }

.icon-battery-50:before {
  font-size : 20px;
  color: #000000;
  content: "\e662"; }

.icon-battery-60:before {
  font-size : 20px;
  color: #000000;
  content: "\e663"; }

.icon-battery-70:before {
  font-size : 20px;
  color: #000000;
  content: "\e664"; }

.icon-battery-80:before {
  font-size : 20px;
  color: #000000;
  content: "\e665"; }

.icon-battery-90:before {
  font-size : 20px;
  color: #000000;
  content: "\e666"; }

.icon-battery-alert:before {
  font-size : 20px;
  color: #000000;
  content: "\e667"; }

.icon-battery-charging-20:before {
  font-size : 20px;
  color: #000000;
  content: "\e668"; }

.icon-battery-charging-30:before {
  font-size : 20px;
  color: #000000;
  content: "\e669"; }

.icon-battery-charging-40:before {
  font-size : 20px;
  color: #000000;
  content: "\e66a"; }

.icon-battery-charging-60:before {
  font-size : 20px;
  color: #000000;
  content: "\e66b"; }

.icon-battery-charging-80:before {
  font-size : 20px;
  color: #000000;
  content: "\e66c"; }

.icon-battery-charging-90:before {
  font-size : 20px;
  color: #000000;
  content: "\e66d"; }

.icon-battery-charging-100:before {
  font-size : 20px;
  color: #000000;
  content: "\e66e"; }

.icon-battery-minus:before {
  font-size : 20px;
  color: #000000;
  content: "\e66f"; }

.icon-battery-negative:before {
  font-size : 20px;
  color: #000000;
  content: "\e670"; }

.icon-battery-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e671"; }

.icon-battery-plus:before {
  font-size : 20px;
  color: #000000;
  content: "\e672"; }

.icon-battery-positive:before {
  font-size : 20px;
  color: #000000;
  content: "\e673"; }

.icon-battery-standard:before {
  font-size : 20px;
  color: #000000;
  content: "\e674"; }

.icon-battery-unknown:before {
  font-size : 20px;
  color: #000000;
  content: "\e675"; }

.icon-battery:before {
  font-size : 20px;
  color: #000000;
  content: "\e676"; }

.icon-beach:before {
  font-size : 20px;
  color: #000000;
  content: "\e677"; }

.icon-beaker-empty-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e678"; }

.icon-beaker-empty:before {
  font-size : 20px;
  color: #000000;
  content: "\e679"; }

.icon-beaker-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e67a"; }

.icon-beaker:before {
  font-size : 20px;
  color: #000000;
  content: "\e67b"; }

.icon-beats:before {
  font-size : 20px;
  color: #000000;
  content: "\e67c"; }

.icon-beer:before {
  font-size : 20px;
  color: #000000;
  content: "\e67d"; }

.icon-behance:before {
  font-size : 20px;
  color: #000000;
  content: "\e67e"; }

.icon-bell-off:before {
  font-size : 20px;
  color: #000000;
  content: "\e67f"; }

.icon-bell-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e680"; }

.icon-bell-ring-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e681"; }

.icon-bell-ring:before {
  font-size : 20px;
  color: #000000;
  content: "\e682"; }

.icon-bell-sleep:before {
  font-size : 20px;
  color: #000000;
  content: "\e683"; }

.icon-bell:before {
  font-size : 20px;
  color: #000000;
  content: "\e684"; }

.icon-beta:before {
  font-size : 20px;
  color: #000000;
  content: "\e685"; }

.icon-bike:before {
  font-size : 20px;
  color: #000000;
  content: "\e686"; }

.icon-bing:before {
  font-size : 20px;
  color: #000000;
  content: "\e687"; }

.icon-binoculars:before {
  font-size : 20px;
  color: #000000;
  content: "\e688"; }

.icon-bio:before {
  font-size : 20px;
  color: #000000;
  content: "\e689"; }

.icon-bitbucket:before {
  font-size : 20px;
  color: #000000;
  content: "\e68a"; }

.icon-black-mesa:before {
  font-size : 20px;
  color: #000000;
  content: "\e68b"; }

.icon-blackberry:before {
  font-size : 20px;
  color: #000000;
  content: "\e68c"; }

.icon-blinds:before {
  font-size : 20px;
  color: #000000;
  content: "\e68d"; }

.icon-block-helper:before {
  font-size : 20px;
  color: #000000;
  content: "\e68e"; }

.icon-blogger:before {
  font-size : 20px;
  color: #000000;
  content: "\e68f"; }

.icon-bluetooth-audio:before {
  font-size : 20px;
  color: #000000;
  content: "\e690"; }

.icon-bluetooth-connect:before {
  font-size : 20px;
  color: #000000;
  content: "\e691"; }

.icon-bluetooth-settings:before {
  font-size : 20px;
  color: #000000;
  content: "\e692"; }

.icon-bluetooth-transfer:before {
  font-size : 20px;
  color: #000000;
  content: "\e693"; }

.icon-bluetooth:before {
  font-size : 20px;
  color: #000000;
  content: "\e694"; }

.icon-blur-linear:before {
  font-size : 20px;
  color: #000000;
  content: "\e695"; }

.icon-blur-off:before {
  font-size : 20px;
  color: #000000;
  content: "\e696"; }

.icon-blur-radial:before {
  font-size : 20px;
  color: #000000;
  content: "\e697"; }

.icon-blur:before {
  font-size : 20px;
  color: #000000;
  content: "\e698"; }

.icon-bone:before {
  font-size : 20px;
  color: #000000;
  content: "\e699"; }

.icon-book-multiple-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\e69a"; }

.icon-book-multiple:before {
  font-size : 20px;
  color: #000000;
  content: "\e69b"; }

.icon-book-open:before {
  font-size : 20px;
  color: #000000;
  content: "\e69c"; }

.icon-book-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\e69d"; }

.icon-book:before {
  font-size : 20px;
  color: #000000;
  content: "\e69e"; }

.icon-bookmark-check:before {
  font-size : 20px;
  color: #000000;
  content: "\e69f"; }

.icon-bookmark-music:before {
  font-size : 20px;
  color: #000000;
  content: "\e6a0"; }

.icon-bookmark-outline-plus:before {
  font-size : 20px;
  color: #000000;
  content: "\e6a1"; }

.icon-bookmark-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e6a2"; }

.icon-bookmark-plus:before {
  font-size : 20px;
  color: #000000;
  content: "\e6a3"; }

.icon-bookmark-remove:before {
  font-size : 20px;
  color: #000000;
  content: "\e6a4"; }

.icon-bookmark:before {
  font-size : 20px;
  color: #000000;
  content: "\e6a5"; }

.icon-border-all:before {
  font-size : 20px;
  color: #000000;
  content: "\e6a6"; }

.icon-border-bottom:before {
  font-size : 20px;
  color: #000000;
  content: "\e6a7"; }

.icon-border-color:before {
  font-size : 20px;
  color: #000000;
  content: "\e6a8"; }

.icon-border-horizontal:before {
  font-size : 20px;
  color: #000000;
  content: "\e6a9"; }

.icon-border-inside:before {
  font-size : 20px;
  color: #000000;
  content: "\e6aa"; }

.icon-border-left:before {
  font-size : 20px;
  color: #000000;
  content: "\e6ab"; }

.icon-border-none:before {
  font-size : 20px;
  color: #000000;
  content: "\e6ac"; }

.icon-border-outside:before {
  font-size : 20px;
  color: #000000;
  content: "\e6ad"; }

.icon-border-right:before {
  font-size : 20px;
  color: #000000;
  content: "\e6ae"; }

.icon-border-top:before {
  font-size : 20px;
  color: #000000;
  content: "\e6af"; }

.icon-border-vertical:before {
  font-size : 20px;
  color: #000000;
  content: "\e6b0"; }

.icon-bowling:before {
  font-size : 20px;
  color: #000000;
  content: "\e6b1"; }

.icon-box-download:before {
  font-size : 20px;
  color: #000000;
  content: "\e6b2"; }

.icon-box-upload:before {
  font-size : 20px;
  color: #000000;
  content: "\e6b3"; }

.icon-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e6b4"; }

.icon-briefcase-checked:before {
  font-size : 20px;
  color: #000000;
  content: "\e6b5"; }

.icon-briefcase-download:before {
  font-size : 20px;
  color: #000000;
  content: "\e6b6"; }

.icon-briefcase-upload:before {
  font-size : 20px;
  color: #000000;
  content: "\e6b7"; }

.icon-briefcase:before {
  font-size : 20px;
  color: #000000;
  content: "\e6b8"; }

.icon-brightness-1:before {
  font-size : 20px;
  color: #000000;
  content: "\e6b9"; }

.icon-brightness-2:before {
  font-size : 20px;
  color: #000000;
  content: "\e6ba"; }

.icon-brightness-3:before {
  font-size : 20px;
  color: #000000;
  content: "\e6bb"; }

.icon-brightness-4:before {
  font-size : 20px;
  color: #000000;
  content: "\e6bc"; }

.icon-brightness-5:before {
  font-size : 20px;
  color: #000000;
  content: "\e6bd"; }

.icon-brightness-6:before {
  font-size : 20px;
  color: #000000;
  content: "\e6be"; }

.icon-brightness-7:before {
  font-size : 20px;
  color: #000000;
  content: "\e6bf"; }

.icon-brightness-auto:before {
  font-size : 20px;
  color: #000000;
  content: "\e6c0"; }

.icon-brightness:before {
  font-size : 20px;
  color: #000000;
  content: "\e6c1"; }

.icon-broom:before {
  font-size : 20px;
  color: #000000;
  content: "\e6c2"; }

.icon-brush:before {
  font-size : 20px;
  color: #000000;
  content: "\e6c3"; }

.icon-bug:before {
  font-size : 20px;
  color: #000000;
  content: "\e6c4"; }

.icon-bulletin-board:before {
  font-size : 20px;
  color: #000000;
  content: "\e6c5"; }

.icon-bullhorn:before {
  font-size : 20px;
  color: #000000;
  content: "\e6c6"; }

.icon-bus:before {
  font-size : 20px;
  color: #000000;
  content: "\e6c7"; }

.icon-cake-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\e6c8"; }

.icon-cake:before {
  font-size : 20px;
  color: #000000;
  content: "\e6c9"; }

.icon-calculator:before {
  font-size : 20px;
  color: #000000;
  content: "\e6ca"; }

.icon-calendar-blank {
  background-image: url('./assets/event.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  height: 16px;
  width: 16px; 
  margin-left: 2px;
 }
//  .icon-calendar-blankwhite {
//   background-image: url('./assets/event.svg');
//   background-size: contain;
//   background-repeat: no-repeat;
//   background-position-x: center;
//   height: 16px;
//   width: 16px; 
//   margin-left: 2px;
//  }
.icon-calendar-check-multiple:before {
  font-size : 20px;
  color: #000000;
  content: "\e6cc"; }

.icon-calendar-check:before {
  font-size : 20px;
  color: #000000;
  content: "\e6cd"; }

.icon-calendar-clock:before {
  font-size : 20px;
  color: #000000;
  content: "\e6ce"; }

.icon-calendar-multiple:before {
  font-size : 20px;
  color: #000000;
  content: "\e6cf"; }

.icon-calendar-plus:before {
  font-size : 20px;
  color: #000000;
  content: "\e6d0"; }

.icon-calendar-remove:before {
  font-size : 20px;
  color: #000000;
  content: "\e6d1"; }

.icon-calendar-select:before {
  font-size : 20px;
  color: #000000;
  content: "\e6d2"; }

.icon-calendar-text:before {
  font-size : 20px;
  color: #000000;
  content: "\e6d3"; }

.icon-calendar-today:before {
  font-size : 20px;
  color: #000000;
  content: "\e6d4"; }

.icon-calendar:before {
  font-size : 20px;
  color: #000000;
  content: "\e6d5"; }

.icon-camcorder-box-off:before {
  font-size : 20px;
  color: #000000;
  content: "\e6d6"; }

.icon-camcorder-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e6d7"; }

.icon-camcorder-off:before {
  font-size : 20px;
  color: #000000;
  content: "\e6d8"; }

.icon-camcorder:before {
  font-size : 20px;
  color: #000000;
  content: "\e6d9"; }

.icon-camera-front-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\e6da"; }

.icon-camera-front:before {
  font-size : 20px;
  color: #000000;
  content: "\e6db"; }

.icon-camera-iris:before {
  font-size : 20px;
  color: #000000;
  content: "\e6dc"; }

.icon-camera-party-mode:before {
  font-size : 20px;
  color: #000000;
  content: "\e6dd"; }

.icon-camera-rear-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\e6de"; }

.icon-camera-rear:before {
  font-size : 20px;
  color: #000000;
  content: "\e6df"; }

.icon-camera-switch:before {
  font-size : 20px;
  color: #000000;
  content: "\e6e0"; }

.icon-camera-timer:before {
  font-size : 20px;
  color: #000000;
  content: "\e6e1"; }

.icon-camera:before {
  font-size : 20px;
  color: #000000;
  content: "\e6e2"; }

.icon-cancel:before {
  font-size : 20px;
  color: #000000;
  content: "\e6e3"; }

.icon-candycane:before {
  font-size : 20px;
  color: #000000;
  content: "\e6e4"; }

.icon-car-wash:before {
  font-size : 20px;
  color: #000000;
  content: "\e6e5"; }

.icon-car:before {
  font-size : 20px;
  color: #000000;
  content: "\e6e6"; }

.icon-carrot:before {
  font-size : 20px;
  color: #000000;
  content: "\e6e7"; }

.icon-cart-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e6e8"; }

.icon-cart:before {
  font-size : 20px;
  color: #000000;
  content: "\e6e9"; }

.icon-cash-100:before {
  font-size : 20px;
  color: #000000;
  content: "\e6ea"; }

.icon-cash-multiple:before {
  font-size : 20px;
  color: #000000;
  content: "\e6eb"; }

.icon-cash-usd:before {
  font-size : 20px;
  color: #000000;
  content: "\e6ec"; }

.icon-cash:before {
  font-size : 20px;
  color: #000000;
  content: "\e6ed"; }

.icon-cast-connected:before {
  font-size : 20px;
  color: #000000;
  content: "\e6ee"; }

.icon-cast:before {
  font-size : 20px;
  color: #000000;
  content: "\e6ef"; }

.icon-castle:before {
  font-size : 20px;
  color: #000000;
  content: "\e6f0"; }

.icon-cat:before {
  font-size : 20px;
  color: #000000;
  content: "\e6f1"; }

.icon-cellphone-android:before {
  font-size : 20px;
  color: #000000;
  content: "\e6f2"; }

.icon-cellphone-dock:before {
  font-size : 20px;
  color: #000000;
  content: "\e6f3"; }

.icon-cellphone-iphone:before {
  font-size : 20px;
  color: #000000;
  content: "\e6f4"; }

.icon-cellphone-link-off:before {
  font-size : 20px;
  color: #000000;
  content: "\e6f5"; }

.icon-cellphone-link:before {
  font-size : 20px;
  color: #000000;
  content: "\e6f6"; }

.icon-cellphone-settings:before {
  font-size : 20px;
  color: #000000;
  content: "\e6f7"; }

.icon-cellphone:before {
  font-size : 20px;
  color: #000000;
  content: "\e6f8"; }

.icon-chair-school:before {
  font-size : 20px;
  color: #000000;
  content: "\e6f9"; }

.icon-chart-arc:before {
  font-size : 20px;
  color: #000000;
  content: "\e6fa"; }

.icon-chart-areaspline:before {
  font-size : 20px;
  color: #000000;
  content: "\e6fb"; }

.icon-chart-bar:before {
  font-size : 20px;
  color: #000000;
  content: "\e6fc"; }


  .icon-chart-barwhite {
    background-image: url('./assets/bar-graph.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    height: 16px;
    width: 16px; 
    margin-left: 2px;
   }


.icon-chart-histogram:before {
  font-size : 20px;
  color: #000000;
  content: "\e6fd"; }

.icon-chart-line:before {
  font-size : 20px;
  color: #000000;
  content: "\e6fe"; }

.icon-chart-pie:before {
  font-size : 20px;
  color: #000000;
  content: "\e6ff"; }

.icon-check-all:before {
  font-size : 20px;
  color: #000000;
  content: "\e700"; }

.icon-check-bookmark:before {
  font-size : 20px;
  color: #000000;
  content: "\e701"; }

.icon-check-circle:before {
  font-size : 20px;
  color: #000000;
  content: "\e702"; }

.icon-check:before {
  font-size : 20px;
  color: #000000;
  content: "\e703"; }

.icon-checkbox-blank-circle-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e704"; }

.icon-checkbox-blank-circle:before {
  font-size : 20px;
  color: #000000;
  content: "\e705"; }

.icon-checkbox-blank-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e706"; }

.icon-checkbox-blank:before {
  font-size : 20px;
  color: #000000;
  content: "\e707"; }

.icon-checkbox-marked-circle-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e708"; }

.icon-checkbox-marked-circle:before {
  font-size : 20px;
  color: #000000;
  content: "\e709"; }

.icon-checkbox-marked-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e70a"; }

.icon-checkbox-marked:before {
  font-size : 20px;
  color: #000000;
  content: "\e70b"; }

.icon-checkbox-multiple-blank-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e70c"; }

.icon-checkbox-multiple-blank:before {
  font-size : 20px;
  color: #000000;
  content: "\e70d"; }

.icon-checkbox-multiple-marked-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e70e"; }

.icon-checkbox-multiple-marked:before {
  font-size : 20px;
  color: #000000;
  content: "\e70f"; }

.icon-checkerboard:before {
  font-size : 20px;
  color: #000000;
  content: "\e710"; }

.icon-chevron-double-down:before {
  font-size : 20px;
  color: #000000;
  content: "\e711"; }

.icon-chevron-double-left:before {
  font-size : 20px;
  color: #000000;
  content: "\e712"; }

.icon-chevron-double-right:before {
  font-size : 20px;
  color: #000000;
  content: "\e713"; }

.icon-chevron-double-up:before {
  font-size : 20px;
  color: #000000;
  content: "\e714"; }

.icon-chevron-down:before {
  font-size : 20px;
  color: #000000;
  content: "\e715"; }

.icon-chevron-left:before {
  font-size : 20px;
  color: #000000;
  content: "\e716"; }

.icon-chevron-right:before {
  font-size : 20px;
  color: #000000;
  content: "\e717"; }

.icon-chevron-up:before {
  font-size : 20px;
  color: #000000;
  content: "\e718"; }

.icon-church:before {
  font-size : 20px;
  color: #000000;
  content: "\e719"; }

.icon-cisco-webex:before {
  font-size : 20px;
  color: #000000;
  content: "\e71a"; }

.icon-city:before {
  font-size : 20px;
  color: #000000;
  content: "\e71b"; }

.icon-clapperboard:before {
  font-size : 20px;
  color: #000000;
  content: "\e71c"; }

.icon-clipboard-account:before {
  font-size : 20px;
  color: #000000;
  content: "\e71d"; }

  .icon-clipboard-accountwhite {
    background-image: url('./assets/account.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    height: 16px;
    width: 16px; 
    margin-left: 2px;
   }
.icon-clipboard-alert:before {
  font-size : 20px;
  color: #000000;
  content: "\e71e"; }

.icon-clipboard-arrow-down:before {
  font-size : 20px;
  color: #000000;
  content: "\e71f"; }

.icon-clipboard-arrow-left:before {
  font-size : 20px;
  color: #000000;
  content: "\e720"; }

.icon-clipboard-check:before {
  font-size : 20px;
  color: #000000;
  content: "\e721"; }

.icon-clipboard-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e722"; }

.icon-clipboard-text:before {
  font-size : 20px;
  color: #000000;
  content: "\e723"; }

.icon-clipboard:before {
  font-size : 20px;
  color: #000000;
  content: "\e724"; }

.icon-clippy:before {
  font-size : 20px;
  color: #000000;
  content: "\e725"; }

.icon-clock-fast:before {
  font-size : 20px;
  color: #000000;
  content: "\e726"; }

.icon-clock:before {
  font-size : 20px;
  color: #000000;
  content: "\e727"; }

.icon-close-circle-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e728"; }

.icon-close-circle:before {
  font-size : 20px;
  color: #000000;
  content: "\e729"; }

.icon-close-network:before {
  font-size : 20px;
  color: #000000;
  content: "\e72a"; }

.icon-close:before {
  font-size : 20px;
  color: #000000;
  content: "\e72b"; }

.icon-closed-caption:before {
  font-size : 20px;
  color: #000000;
  content: "\e72c"; }

.icon-cloud-check:before {
  font-size : 20px;
  color: #000000;
  content: "\e72d"; }

.icon-cloud-circle:before {
  font-size : 20px;
  color: #000000;
  content: "\e72e"; }

.icon-cloud-download:before {
  font-size : 20px;
  color: #000000;
  content: "\e72f"; }

.icon-cloud-outline-off:before {
  font-size : 20px;
  color: #000000;
  content: "\e730"; }

.icon-cloud-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e731"; }

.icon-cloud-upload:before {
  font-size : 20px;
  color: #000000;
  content: "\e732"; }

.icon-cloud:before {
  font-size : 20px;
  color: #000000;
  content: "\e733"; }

.icon-code-array:before {
  font-size : 20px;
  color: #000000;
  content: "\e734"; }

.icon-code-braces:before {
  font-size : 20px;
  color: #000000;
  content: "\e735"; }

.icon-code-equal:before {
  font-size : 20px;
  color: #000000;
  content: "\e736"; }

.icon-code-greater-than-or-equal:before {
  font-size : 20px;
  color: #000000;
  content: "\e737"; }

.icon-code-greater-than:before {
  font-size : 20px;
  color: #000000;
  content: "\e738"; }

.icon-code-less-than-or-equal:before {
  font-size : 20px;
  color: #000000;
  content: "\e739"; }

.icon-code-less-than:before {
  font-size : 20px;
  color: #000000;
  content: "\e73a"; }

.icon-code-not-equal-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\e73b"; }

.icon-code-not-equal:before {
  font-size : 20px;
  color: #000000;
  content: "\e73c"; }

.icon-code-string:before {
  font-size : 20px;
  color: #000000;
  content: "\e73d"; }

.icon-code-tags:before {
  font-size : 20px;
  color: #000000;
  content: "\e73e"; }

.icon-codepen:before {
  font-size : 20px;
  color: #000000;
  content: "\e73f"; }

.icon-coffee-to-go:before {
  font-size : 20px;
  color: #000000;
  content: "\e740"; }

.icon-coffee:before {
  font-size : 20px;
  color: #000000;
  content: "\e741"; }

.icon-cog-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e742"; }

  .icon-cogwhite {
    background-image: url('./assets/setting.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    height: 16px;
    width: 16px; 
    margin-left: 2px;
   }





.icon-cog:before {
  font-size : 20px;
  color: #000000;
  content: "\e743"; }

.icon-coin:before {
  font-size : 20px;
  color: #000000;
  content: "\e744"; }

.icon-color-helper:before {
  font-size : 20px;
  color: #000000;
  content: "\e745"; }

.icon-comment-account-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e746"; }

.icon-comment-account:before {
  font-size : 20px;
  color: #000000;
  content: "\e747"; }

.icon-comment-alert-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e748"; }

.icon-comment-alert:before {
  font-size : 20px;
  color: #000000;
  content: "\e749"; }

.icon-comment-check-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e74a"; }

.icon-comment-check:before {
  font-size : 20px;
  color: #000000;
  content: "\e74b"; }

.icon-comment-multipe-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e74c"; }

.icon-comment-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e74d"; }

.icon-comment-plus-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e74e"; }

.icon-comment-processing-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e74f"; }

.icon-comment-processing:before {
  font-size : 20px;
  color: #000000;
  content: "\e750"; }

.icon-comment-remove-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e751"; }

.icon-comment-text-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e752"; }

.icon-comment-text:before {
  font-size : 20px;
  color: #000000;
  content: "\e753"; }

.icon-comment:before {
  font-size : 20px;
  color: #000000;
  content: "\e754"; }

.icon-compare:before {
  font-size : 20px;
  color: #000000;
  content: "\e755"; }

.icon-compass-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e756"; }

.icon-compass:before {
  font-size : 20px;
  color: #000000;
  content: "\e757"; }

.icon-console:before {
  font-size : 20px;
  color: #000000;
  content: "\e758"; }

.icon-content-copy:before {
  font-size : 20px;
  color: #000000;
  content: "\e759"; }

.icon-content-cut:before {
  font-size : 20px;
  color: #000000;
  content: "\e75a"; }

.icon-content-duplicate:before {
  font-size : 20px;
  color: #000000;
  content: "\e75b"; }

.icon-content-paste:before {
  font-size : 20px;
  color: #000000;
  content: "\e75c"; }

.icon-content-save-all:before {
  font-size : 20px;
  color: #000000;
  content: "\e75d"; }

.icon-content-save:before {
  font-size : 20px;
  color: #000000;
  content: "\e75e"; }

.icon-contrast-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e75f"; }

.icon-contrast-circle:before {
  font-size : 20px;
  color: #000000;
  content: "\e760"; }

.icon-contrast:before {
  font-size : 20px;
  color: #000000;
  content: "\e761"; }

.icon-controller-xbox:before {
  font-size : 20px;
  color: #000000;
  content: "\e762"; }

.icon-cow:before {
  font-size : 20px;
  color: #000000;
  content: "\e763"; }

.icon-credit-card-multiple:before {
  font-size : 20px;
  color: #000000;
  content: "\e764"; }

.icon-credit-card:before {
  font-size : 20px;
  color: #000000;
  content: "\e765"; }

.icon-crop-free:before {
  font-size : 20px;
  color: #000000;
  content: "\e766"; }

.icon-crop-landscape:before {
  font-size : 20px;
  color: #000000;
  content: "\e767"; }

.icon-crop-portrait:before {
  font-size : 20px;
  color: #000000;
  content: "\e768"; }

.icon-crop-square:before {
  font-size : 20px;
  color: #000000;
  content: "\e769"; }

.icon-crop:before {
  font-size : 20px;
  color: #000000;
  content: "\e76a"; }

.icon-crosshairs-gps:before {
  font-size : 20px;
  color: #000000;
  content: "\e76b"; }

.icon-crosshairs:before {
  font-size : 20px;
  color: #000000;
  content: "\e76c"; }

.icon-crown:before {
  font-size : 20px;
  color: #000000;
  content: "\e76d"; }

.icon-cube-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e76e"; }

.icon-cube-unfolded:before {
  font-size : 20px;
  color: #000000;
  content: "\e76f"; }

.icon-cube:before {
  font-size : 20px;
  color: #000000;
  content: "\e770"; }



  .icon-cubewhite {
    background-image: url('./assets/box.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    height: 16px;
    width: 16px; 
    margin-left: 2px;
   }









.icon-cup-water:before {
  font-size : 20px;
  color: #000000;
  content: "\e771"; }

.icon-cup:before {
  font-size : 20px;
  color: #000000;
  content: "\e772"; }

.icon-currency-btc:before {
  font-size : 20px;
  color: #000000;
  content: "\e773"; }

.icon-currency-eur:before {
  font-size : 20px;
  color: #000000;
  content: "\e774"; }

.icon-currency-gbp:before {
  font-size : 20px;
  color: #000000;
  content: "\e775"; }

.icon-currency-inr:before {
  font-size : 20px;
  color: #000000;
  content: "\e776"; }

.icon-currency-rub:before {
  font-size : 20px;
  color: #000000;
  content: "\e777"; }

.icon-currency-try:before {
  font-size : 20px;
  color: #000000;
  content: "\e778"; }

.icon-currency-usd:before {
  font-size : 20px;
  color: #000000;
  content: "\e779"; }

.icon-cursor-default-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e77a"; }

.icon-cursor-default:before {
  font-size : 20px;
  color: #000000;
  content: "\e77b"; }

.icon-cursor-move:before {
  font-size : 20px;
  color: #000000;
  content: "\e77c"; }

.icon-cursor-pointer:before {
  font-size : 20px;
  color: #000000;
  content: "\e77d"; }

.icon-data:before {
  font-size : 20px;
  color: #000000;
  content: "\e77e"; }

.icon-debug-step-into:before {
  font-size : 20px;
  color: #000000;
  content: "\e77f"; }

.icon-debug-step-out:before {
  font-size : 20px;
  color: #000000;
  content: "\e780"; }

.icon-debug-step-over:before {
  font-size : 20px;
  color: #000000;
  content: "\e781"; }

.icon-decimal-decrease:before {
  font-size : 20px;
  color: #000000;
  content: "\e782"; }

.icon-decimal-increase:before {
  font-size : 20px;
  color: #000000;
  content: "\e783"; }

.icon-delete-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\e784"; }

.icon-delete:before {
  font-size : 20px;
  color: #000000;
  content: "\e785"; }

.icon-deskphone:before {
  font-size : 20px;
  color: #000000;
  content: "\e786"; }

.icon-desktop-mac:before {
  font-size : 20px;
  color: #000000;
  content: "\e787"; }

.icon-desktop-tower:before {
  font-size : 20px;
  color: #000000;
  content: "\e788"; }

.icon-details:before {
  font-size : 20px;
  color: #000000;
  content: "\e789"; }

.icon-deviantart:before {
  font-size : 20px;
  color: #000000;
  content: "\e78a"; }

.icon-diamond:before {
  font-size : 20px;
  color: #000000;
  content: "\e78b"; }

.icon-dice-1:before {
  font-size : 20px;
  color: #000000;
  content: "\e78c"; }

.icon-dice-2:before {
  font-size : 20px;
  color: #000000;
  content: "\e78d"; }

.icon-dice-3:before {
  font-size : 20px;
  color: #000000;
  content: "\e78e"; }

.icon-dice-4:before {
  font-size : 20px;
  color: #000000;
  content: "\e78f"; }

.icon-dice-5:before {
  font-size : 20px;
  color: #000000;
  content: "\e790"; }

.icon-dice-6:before {
  font-size : 20px;
  color: #000000;
  content: "\e791"; }

.icon-dice:before {
  font-size : 20px;
  color: #000000;
  content: "\e792"; }

.icon-directions:before {
  font-size : 20px;
  color: #000000;
  content: "\e793"; }

.icon-disk-alert:before {
  font-size : 20px;
  color: #000000;
  content: "\e794"; }

.icon-disqus-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e795"; }

.icon-disqus:before {
  font-size : 20px;
  color: #000000;
  content: "\e796"; }

.icon-division-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e797"; }

.icon-division:before {
  font-size : 20px;
  color: #000000;
  content: "\e798"; }

.icon-dns:before {
  font-size : 20px;
  color: #000000;
  content: "\e799"; }

.icon-document:before {
  font-size : 20px;
  color: #000000;
  content: "\e79a"; }

.icon-domain:before {
  font-size : 20px;
  color: #000000;
  content: "\e79b"; }

.icon-dots-horizontal:before {
  font-size : 20px;
  color: #000000;
  content: "\e79c"; }

.icon-dots-vertical:before {
  font-size : 20px;
  color: #000000;
  content: "\e79d"; }

.icon-download:before {
  font-size : 20px;
  color: #000000;
  content: "\e79e"; }

.icon-drag-horizontal:before {
  font-size : 20px;
  color: #000000;
  content: "\e79f"; }

.icon-drag-vertical:before {
  font-size : 20px;
  color: #000000;
  content: "\e7a0"; }

.icon-drag:before {
  font-size : 20px;
  color: #000000;
  content: "\e7a1"; }

.icon-drawing-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e7a2"; }

.icon-drawing:before {
  font-size : 20px;
  color: #000000;
  content: "\e7a3"; }

.icon-dribbble-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e7a4"; }

.icon-dribbble:before {
  font-size : 20px;
  color: #000000;
  content: "\e7a5"; }

.icon-drone:before {
  font-size : 20px;
  color: #000000;
  content: "\e7a6"; }

.icon-dropbox:before {
  font-size : 20px;
  color: #000000;
  content: "\e7a7"; }

.icon-drupal:before {
  font-size : 20px;
  color: #000000;
  content: "\e7a8"; }

.icon-duck:before {
  font-size : 20px;
  color: #000000;
  content: "\e7a9"; }

.icon-dumbbell:before {
  font-size : 20px;
  color: #000000;
  content: "\e7aa"; }

.icon-earth-off:before {
  font-size : 20px;
  color: #000000;
  content: "\e7ab"; }

.icon-earth:before {
  font-size : 20px;
  color: #000000;
  content: "\e7ac"; }

.icon-ebay:before {
  font-size : 20px;
  color: #000000;
  content: "\e7ad"; }

.icon-edge:before {
  font-size : 20px;
  color: #000000;
  content: "\e7ae"; }

.icon-eject:before {
  font-size : 20px;
  color: #000000;
  content: "\e7af"; }

.icon-elevation-decline:before {
  font-size : 20px;
  color: #000000;
  content: "\e7b0"; }

.icon-elevation-rise:before {
  font-size : 20px;
  color: #000000;
  content: "\e7b1"; }

.icon-elevator:before {
  font-size : 20px;
  color: #000000;
  content: "\e7b2"; }

.icon-email-open:before {
  font-size : 20px;
  color: #000000;
  content: "\e7b3"; }

.icon-email-secure:before {
  font-size : 20px;
  color: #000000;
  content: "\e7b4"; }

.icon-email:before {
  font-size : 20px;
  color: #000000;
  content: "\e7b5"; }

.icon-emoticon-cool:before {
  font-size : 20px;
  color: #000000;
  content: "\e7b6"; }

.icon-emoticon-devil:before {
  font-size : 20px;
  color: #000000;
  content: "\e7b7"; }

.icon-emoticon-happy:before {
  font-size : 20px;
  color: #000000;
  content: "\e7b8"; }

.icon-emoticon-neutral:before {
  font-size : 20px;
  color: #000000;
  content: "\e7b9"; }

.icon-emoticon-poop:before {
  font-size : 20px;
  color: #000000;
  content: "\e7ba"; }

.icon-emoticon-sad:before {
  font-size : 20px;
  color: #000000;
  content: "\e7bb"; }

.icon-emoticon-tongue:before {
  font-size : 20px;
  color: #000000;
  content: "\e7bc"; }

.icon-emoticon:before {
  font-size : 20px;
  color: #000000;
  content: "\e7bd"; }

.icon-engine-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e7be"; }

.icon-engine:before {
  font-size : 20px;
  color: #000000;
  content: "\e7bf"; }

.icon-equal-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e7c0"; }

.icon-equal:before {
  font-size : 20px;
  color: #000000;
  content: "\e7c1"; }

.icon-eraser:before {
  font-size : 20px;
  color: #000000;
  content: "\e7c2"; }

.icon-escalator:before {
  font-size : 20px;
  color: #000000;
  content: "\e7c3"; }

.icon-etsy:before {
  font-size : 20px;
  color: #000000;
  content: "\e7c4"; }

.icon-evernote:before {
  font-size : 20px;
  color: #000000;
  content: "\e7c5"; }

.icon-exclamation:before {
  font-size : 20px;
  color: #000000;
  content: "\e7c6"; }

.icon-exit-to-app:before {
  font-size : 20px;
  color: #000000;
  content: "\e7c7"; }

.icon-export:before {
  font-size : 20px;
  color: #000000;
  content: "\e7c8"; }

.icon-eye-off:before {
  font-size : 20px;
  color: #000000;
  content: "\e7c9"; }

.icon-eye:before {
  font-size : 20px;
  color: #000000;
  content: "\e7ca"; }

.icon-eyedropper-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\e7cb"; }

.icon-eyedropper:before {
  font-size : 20px;
  color: #000000;
  content: "\e7cc"; }

.icon-facebook-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e7cd"; }

.icon-facebook:before {
  font-size : 20px;
  color: #000000;
  content: "\e7ce"; }

.icon-factory:before {
  font-size : 20px;
  color: #000000;
  content: "\e7cf"; }

.icon-fan:before {
  font-size : 20px;
  color: #000000;
  content: "\e7d0"; }

.icon-fast-forward:before {
  font-size : 20px;
  color: #000000;
  content: "\e7d1"; }

.icon-ferry:before {
  font-size : 20px;
  color: #000000;
  content: "\e7d2"; }

.icon-file-cloud:before {
  font-size : 20px;
  color: #000000;
  content: "\e7d3"; }

.icon-file-delimited:before {
  font-size : 20px;
  color: #000000;
  content: "\e7d4"; }

.icon-file-document-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e7d5"; }

.icon-file-document:before {
  font-size : 20px;
  color: #000000;
  content: "\e7d6"; }

.icon-file-excel-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e7d7"; }

.icon-file-excel:before {
  font-size : 20px;
  color: #000000;
  content: "\e7d8"; }

.icon-file-find:before {
  font-size : 20px;
  color: #000000;
  content: "\e7d9"; }

.icon-file-hidden:before {
  font-size : 20px;
  color: #000000;
  content: "\e7da"; }

.icon-file-image-box {
  background-image: url('./assets/theme.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  height: 16px;
  width: 16px; 
  margin-left: 2px;
}
// .icon-file-image-boxwhite {
//   background-image: url('./assets/theme.svg');
//   background-size: contain;
//   background-repeat: no-repeat;
//   background-position-x: center;
//   height: 16px;
//   width: 16px; 
//   margin-left: 2px;
// }
.icon-file-image:before {
  font-size : 20px;
  color: #000000;
  content: "\e7dc"; }

.icon-file-multiple:before {
  font-size : 20px;
  color: #000000;
  content: "\e7dd"; }

.icon-file-music:before {
  font-size : 20px;
  color: #000000;
  content: "\e7de"; }

.icon-file-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e7df"; }

.icon-file-pdf-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e7e0"; }

.icon-file-pdf:before {
  font-size : 20px;
  color: #000000;
  content: "\e7e1"; }

.icon-file-powerpoint-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e7e2"; }

.icon-file-powerpoint:before {
  font-size : 20px;
  color: #000000;
  content: "\e7e3"; }

.icon-file-presentation-box {
  background-image: url('./assets/config.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  height: 16px;
  width: 16px; 
  margin-left: 2px;
}

.icon-file-video:before {
  font-size : 20px;
  color: #000000;
  content: "\e7e5"; }

.icon-file-word-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e7e6"; }

.icon-file-word:before {
  font-size : 20px;
  color: #000000;
  content: "\e7e7"; }

.icon-file-xml:before {
  font-size : 20px;
  color: #000000;
  content: "\e7e8"; }

.icon-file:before {
  font-size : 20px;
  color: #000000;
  content: "\e7e9"; }

.icon-fill:before {
  font-size : 20px;
  color: #000000;
  content: "\e7ea"; }

.icon-film:before {
  font-size : 20px;
  color: #000000;
  content: "\e7eb"; }


  .icon-filmwhite {
    background-image: url('./assets/boitier.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    height: 16px;
    width: 16px; 
    margin-left: 2px;
  }
  




.icon-filmstrip-off:before {
  font-size : 20px;
  color: #000000;
  content: "\e7ec"; }

.icon-filmstrip:before {
  font-size : 20px;
  color: #000000;
  content: "\e7ed"; }

.icon-filter-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e7ee"; }

.icon-filter-remove-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e7ef"; }

.icon-filter-remove:before {
  font-size : 20px;
  color: #000000;
  content: "\e7f0"; }

.icon-filter-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\e7f1"; }

.icon-filter:before {
  font-size : 20px;
  color: #000000;
  content: "\e7f2"; }

.icon-fire:before {
  font-size : 20px;
  color: #000000;
  content: "\e7f3"; }

.icon-fish:before {
  font-size : 20px;
  color: #000000;
  content: "\e7f4"; }

.icon-flag-checkered-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\e7f5"; }

.icon-flag-checkered:before {
  font-size : 20px;
  color: #000000;
  content: "\e7f6"; }

.icon-flag-outline-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\e7f7"; }

.icon-flag-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e7f8"; }

.icon-flag-triangle:before {
  font-size : 20px;
  color: #000000;
  content: "\e7f9"; }

.icon-flag-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\e7fa"; }

.icon-flag:before {
  font-size : 20px;
  color: #000000;
  content: "\e7fb"; }

.icon-flash-auto:before {
  font-size : 20px;
  color: #000000;
  content: "\e7fc"; }

.icon-flash-off:before {
  font-size : 20px;
  color: #000000;
  content: "\e7fd"; }

.icon-flash:before {
  font-size : 20px;
  color: #000000;
  content: "\e7fe"; }

.icon-flashlight-off:before {
  font-size : 20px;
  color: #000000;
  content: "\e7ff"; }

.icon-flashlight:before {
  font-size : 20px;
  color: #000000;
  content: "\e800"; }

.icon-flattr:before {
  font-size : 20px;
  color: #000000;
  content: "\e801"; }

.icon-flickr-after:before {
  font-size : 20px;
  color: #000000;
  content: "\e802"; }

.icon-flickr-before:before {
  font-size : 20px;
  color: #000000;
  content: "\e803"; }

.icon-flip-to-back:before {
  font-size : 20px;
  color: #000000;
  content: "\e804"; }

.icon-flip-to-front:before {
  font-size : 20px;
  color: #000000;
  content: "\e805"; }

.icon-floppy:before {
  font-size : 20px;
  color: #000000;
  content: "\e806"; }

.icon-flower:before {
  font-size : 20px;
  color: #000000;
  content: "\e807"; }

.icon-folder-account:before {
  font-size : 20px;
  color: #000000;
  content: "\e808"; }

.icon-folder-download:before {
  font-size : 20px;
  color: #000000;
  content: "\e809"; }

.icon-folder-google-drive:before {
  font-size : 20px;
  color: #000000;
  content: "\e80a"; }

.icon-folder-image:before {
  font-size : 20px;
  color: #000000;
  content: "\e80b"; }

.icon-folder-lock-open:before {
  font-size : 20px;
  color: #000000;
  content: "\e80c"; }

.icon-folder-lock:before {
  font-size : 20px;
  color: #000000;
  content: "\e80d"; }

.icon-folder-move:before {
  font-size : 20px;
  color: #000000;
  content: "\e80e"; }

.icon-folder-multiple-image:before {
  font-size : 20px;
  color: #000000;
  content: "\e80f"; }

.icon-folder-multiple-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e810"; }

.icon-folder-multiple:before {
  font-size : 20px;
  color: #000000;
  content: "\e811"; }

.icon-folder-outline-lock:before {
  font-size : 20px;
  color: #000000;
  content: "\e812"; }

.icon-folder-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e813"; }

.icon-folder-plus:before {
  font-size : 20px;
  color: #000000;
  content: "\e814"; }

.icon-folder-remove:before {
  font-size : 20px;
  color: #000000;
  content: "\e815"; }

.icon-folder-upload:before {
  font-size : 20px;
  color: #000000;
  content: "\e816"; }

.icon-folder {
  background-image: url('./assets/dossier.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  height: 16px;
  width: 16px; 
  margin-left: 2px;
}

.icon-food-apple:before {
  font-size : 20px;
  color: #000000;
  content: "\e818"; }

.icon-food-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\e819"; }

.icon-food:before {
  font-size : 20px;
  color: #000000;
  content: "\e81a"; }

.icon-football-helmet:before {
  font-size : 20px;
  color: #000000;
  content: "\e81b"; }

.icon-football:before {
  font-size : 20px;
  color: #000000;
  content: "\e81c"; }

.icon-format-align-center:before {
  font-size : 20px;
  color: #000000;
  content: "\e81d"; }

.icon-format-align-justify:before {
  font-size : 20px;
  color: #000000;
  content: "\e81e"; }

.icon-format-align-left:before {
  font-size : 20px;
  color: #000000;
  content: "\e81f"; }

.icon-format-align-right:before {
  font-size : 20px;
  color: #000000;
  content: "\e820"; }

.icon-format-bold:before {
  font-size : 20px;
  color: #000000;
  content: "\e821"; }

.icon-format-clear:before {
  font-size : 20px;
  color: #000000;
  content: "\e822"; }

.icon-format-color-fill:before {
  font-size : 20px;
  color: #000000;
  content: "\e823"; }

.icon-format-color:before {
  font-size : 20px;
  color: #000000;
  content: "\e824"; }

.icon-format-float-center:before {
  font-size : 20px;
  color: #000000;
  content: "\e825"; }

.icon-format-float-left:before {
  font-size : 20px;
  color: #000000;
  content: "\e826"; }

.icon-format-float-none:before {
  font-size : 20px;
  color: #000000;
  content: "\e827"; }

.icon-format-float-right:before {
  font-size : 20px;
  color: #000000;
  content: "\e828"; }

.icon-format-header-1:before {
  font-size : 20px;
  color: #000000;
  content: "\e829"; }

.icon-format-header-2:before {
  font-size : 20px;
  color: #000000;
  content: "\e82a"; }

.icon-format-header-3:before {
  font-size : 20px;
  color: #000000;
  content: "\e82b"; }

.icon-format-header-4:before {
  font-size : 20px;
  color: #000000;
  content: "\e82c"; }

.icon-format-header-5:before {
  font-size : 20px;
  color: #000000;
  content: "\e82d"; }

.icon-format-header-6:before {
  font-size : 20px;
  color: #000000;
  content: "\e82e"; }

.icon-format-header-decrease:before {
  font-size : 20px;
  color: #000000;
  content: "\e82f"; }

.icon-format-header-down:before {
  font-size : 20px;
  color: #000000;
  content: "\e830"; }

.icon-format-header-equal:before {
  font-size : 20px;
  color: #000000;
  content: "\e831"; }

.icon-format-header-increase:before {
  font-size : 20px;
  color: #000000;
  content: "\e832"; }

.icon-format-header-pound:before {
  font-size : 20px;
  color: #000000;
  content: "\e833"; }

.icon-format-header-up:before {
  font-size : 20px;
  color: #000000;
  content: "\e834"; }

.icon-format-indent-decrease:before {
  font-size : 20px;
  color: #000000;
  content: "\e835"; }

.icon-format-indent-increase {
  background-image: url('./assets/playliste.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  height: 16px;
  width: 16px; 
  margin-left: 2px;
}

.icon-format-italic:before {
  font-size : 20px;
  color: #000000;
  content: "\e837"; }

.icon-format-line-spacing:before {
  font-size : 20px;
  color: #000000;
  content: "\e838"; }

.icon-format-list-bulleted:before {
  font-size : 20px;
  color: #000000;
  content: "\e839"; }

.icon-format-list-numbers:before {
  font-size : 20px;
  color: #000000;
  content: "\e83a"; }

.icon-format-object-inline:before {
  font-size : 20px;
  color: #000000;
  content: "\e83b"; }

.icon-format-object-square:before {
  font-size : 20px;
  color: #000000;
  content: "\e83c"; }

.icon-format-object-tight:before {
  font-size : 20px;
  color: #000000;
  content: "\e83d"; }

.icon-format-object-top-bottom:before {
  font-size : 20px;
  color: #000000;
  content: "\e83e"; }

.icon-format-paint:before {
  font-size : 20px;
  color: #000000;
  content: "\e83f"; }

.icon-format-paragraph:before {
  font-size : 20px;
  color: #000000;
  content: "\e840"; }

.icon-format-quote:before {
  font-size : 20px;
  color: #000000;
  content: "\e841"; }

.icon-format-size:before {
  font-size : 20px;
  color: #000000;
  content: "\e842"; }

.icon-format-strikethrough:before {
  font-size : 20px;
  color: #000000;
  content: "\e843"; }

.icon-format-subscript:before {
  font-size : 20px;
  color: #000000;
  content: "\e844"; }

.icon-format-superscript:before {
  font-size : 20px;
  color: #000000;
  content: "\e845"; }

.icon-format-text:before {
  font-size : 20px;
  color: #000000;
  content: "\e846"; }

.icon-format-textdirection-l-to-r:before {
  font-size : 20px;
  color: #000000;
  content: "\e847"; }

.icon-format-textdirection-r-to-l:before {
  font-size : 20px;
  color: #000000;
  content: "\e848"; }

.icon-format-underline:before {
  font-size : 20px;
  color: #000000;
  content: "\e849"; }

.icon-forum:before {
  font-size : 20px;
  color: #000000;
  content: "\e84a"; }

.icon-forward:before {
  font-size : 20px;
  color: #000000;
  content: "\e84b"; }

.icon-foursquare:before {
  font-size : 20px;
  color: #000000;
  content: "\e84c"; }

.icon-fridge:before {
  font-size : 20px;
  color: #000000;
  content: "\e84d"; }

.icon-fullscreen-exit:before {
  font-size : 20px;
  color: #000000;
  content: "\e84e"; }

.icon-fullscreen:before {
  font-size : 20px;
  color: #000000;
  content: "\e84f"; }

.icon-function:before {
  font-size : 20px;
  color: #000000;
  content: "\e850"; }

.icon-gamepad-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\e851"; }

.icon-gamepad:before {
  font-size : 20px;
  color: #000000;
  content: "\e852"; }

.icon-gas-station:before {
  font-size : 20px;
  color: #000000;
  content: "\e853"; }

.icon-gavel:before {
  font-size : 20px;
  color: #000000;
  content: "\e854"; }

.icon-gender-female:before {
  font-size : 20px;
  color: #000000;
  content: "\e855"; }

.icon-gender-male-female:before {
  font-size : 20px;
  color: #000000;
  content: "\e856"; }

.icon-gender-male:before {
  font-size : 20px;
  color: #000000;
  content: "\e857"; }

.icon-gender-transgender:before {
  font-size : 20px;
  color: #000000;
  content: "\e858"; }

.icon-gift:before {
  font-size : 20px;
  color: #000000;
  content: "\e859"; }

.icon-git:before {
  font-size : 20px;
  color: #000000;
  content: "\e85a"; }

.icon-github-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e85b"; }

.icon-github:before {
  font-size : 20px;
  color: #000000;
  content: "\e85c"; }

.icon-glass-flute:before {
  font-size : 20px;
  color: #000000;
  content: "\e85d"; }

.icon-glass-mug:before {
  font-size : 20px;
  color: #000000;
  content: "\e85e"; }

.icon-glass-stange:before {
  font-size : 20px;
  color: #000000;
  content: "\e85f"; }

.icon-glass-tulip:before {
  font-size : 20px;
  color: #000000;
  content: "\e860"; }

.icon-glasses:before {
  font-size : 20px;
  color: #000000;
  content: "\e861"; }

.icon-gmail:before {
  font-size : 20px;
  color: #000000;
  content: "\e862"; }

.icon-google-chrome:before {
  font-size : 20px;
  color: #000000;
  content: "\e863"; }

.icon-google-circles-communities:before {
  font-size : 20px;
  color: #000000;
  content: "\e864"; }

.icon-google-circles-extended:before {
  font-size : 20px;
  color: #000000;
  content: "\e865"; }

.icon-google-circles-invite:before {
  font-size : 20px;
  color: #000000;
  content: "\e866"; }

.icon-google-circles:before {
  font-size : 20px;
  color: #000000;
  content: "\e867"; }

.icon-google-controller-off:before {
  font-size : 20px;
  color: #000000;
  content: "\e868"; }

.icon-google-controller:before {
  font-size : 20px;
  color: #000000;
  content: "\e869"; }

.icon-google-drive:before {
  font-size : 20px;
  color: #000000;
  content: "\e86a"; }

.icon-google-earth:before {
  font-size : 20px;
  color: #000000;
  content: "\e86b"; }

.icon-google-glass:before {
  font-size : 20px;
  color: #000000;
  content: "\e86c"; }

.icon-google-maps:before {
  font-size : 20px;
  color: #000000;
  content: "\e86d"; }

.icon-google-pages:before {
  font-size : 20px;
  color: #000000;
  content: "\e86e"; }

.icon-google-play:before {
  font-size : 20px;
  color: #000000;
  content: "\e86f"; }

.icon-google-plus-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e870"; }

.icon-google-plus:before {
  font-size : 20px;
  color: #000000;
  content: "\e871"; }

.icon-google:before {
  font-size : 20px;
  color: #000000;
  content: "\e872"; }

.icon-grid-off:before {
  font-size : 20px;
  color: #000000;
  content: "\e873"; }

.icon-grid:before {
  font-size : 20px;
  color: #000000;
  content: "\e874"; }

.icon-group:before {
  font-size : 20px;
  color: #000000;
  content: "\e875"; }

.icon-hand-pointing-right:before {
  font-size : 20px;
  color: #000000;
  content: "\e876"; }

.icon-hanger:before {
  font-size : 20px;
  color: #000000;
  content: "\e877"; }

.icon-hangouts:before {
  font-size : 20px;
  color: #000000;
  content: "\e878"; }

.icon-harddisk:before {
  font-size : 20px;
  color: #000000;
  content: "\e879"; }

.icon-headphones-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e87a"; }

.icon-headphones-dots:before {
  font-size : 20px;
  color: #000000;
  content: "\e87b"; }

.icon-headphones:before {
  font-size : 20px;
  color: #000000;
  content: "\e87c"; }

.icon-headset-dock:before {
  font-size : 20px;
  color: #000000;
  content: "\e87d"; }

.icon-headset-off:before {
  font-size : 20px;
  color: #000000;
  content: "\e87e"; }

.icon-headset:before {
  font-size : 20px;
  color: #000000;
  content: "\e87f"; }

.icon-heart-box-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e880"; }

.icon-heart-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e881"; }

.icon-heart-broken:before {
  font-size : 20px;
  color: #000000;
  content: "\e882"; }

.icon-heart-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e883"; }

.icon-heart:before {
  font-size : 20px;
  color: #000000;
  content: "\e884"; }

.icon-help-circle:before {
  font-size : 20px;
  color: #000000;
  content: "\e885"; }

.icon-help:before {
  font-size : 20px;
  color: #000000;
  content: "\e886"; }

.icon-hexagon-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e887"; }

.icon-hexagon:before {
  font-size : 20px;
  color: #000000;
  content: "\e888"; }

.icon-history:before {
  font-size : 20px;
  color: #000000;
  content: "\e889"; }

.icon-hololens:before {
  font-size : 20px;
  color: #000000;
  content: "\e88a"; }

.icon-home-modern:before {
  font-size : 20px;
  color: #000000;
  content: "\e88b"; }

.icon-home-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\e88c"; }

.icon-home:before {
  font-size : 20px;
  color: #000000;
  content: "\eb0a"; }

.icon-hops:before {
  font-size : 20px;
  color: #000000;
  content: "\e88e"; }

.icon-hospital-building:before {
  font-size : 20px;
  color: #000000;
  content: "\e88f"; }

.icon-hospital-marker:before {
  font-size : 20px;
  color: #000000;
  content: "\e890"; }

.icon-hospital:before {
  font-size : 20px;
  color: #000000;
  content: "\e891"; }

.icon-hotel:before {
  font-size : 20px;
  color: #000000;
  content: "\e892"; }

.icon-houzz-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e893"; }
  .icon-houzz-boxwhite:before {
    font-size : 20px;
    color: #ffffff;
    content: "\e893"; }
.icon-human-child:before {
  font-size : 20px;
  color: #000000;
  content: "\e894"; }

.icon-human-male-female:before {
  font-size : 20px;
  color: #000000;
  content: "\e895"; }

.icon-human:before {
  font-size : 20px;
  color: #000000;
  content: "\e896"; }

.icon-image-album:before {
  font-size : 20px;
  color: #000000;
  content: "\e897"; }

.icon-image-area-close:before {
  font-size : 20px;
  color: #000000;
  content: "\e898"; }

.icon-image-area:before {
  font-size : 20px;
  color: #000000;
  content: "\e899"; }

.icon-image-broken:before {
  font-size : 20px;
  color: #000000;
  content: "\e89a"; }

.icon-image-filter-black-white:before {
  font-size : 20px;
  color: #000000;
  content: "\e89b"; }

.icon-image-filter-center-focus:before {
  font-size : 20px;
  color: #000000;
  content: "\e89c"; }

.icon-image-filter-drama:before {
  font-size : 20px;
  color: #000000;
  content: "\e89d"; }

.icon-image-filter-frames:before {
  font-size : 20px;
  color: #000000;
  content: "\e89e"; }

.icon-image-filter-hdr:before {
  font-size : 20px;
  color: #000000;
  content: "\e89f"; }

.icon-image-filter-none:before {
  font-size : 20px;
  color: #000000;
  content: "\e8a0"; }

.icon-image-filter-tilt-shift:before {
  font-size : 20px;
  color: #000000;
  content: "\e8a1"; }

.icon-image-filter-vintage:before {
  font-size : 20px;
  color: #000000;
  content: "\e8a2"; }

.icon-image-filter:before {
  font-size : 20px;
  color: #000000;
  content: "\e8a3"; }

.icon-image:before {
  font-size : 20px;
  color: #000000;
  content: "\e8a4"; }

.icon-import:before {
  font-size : 20px;
  color: #000000;
  content: "\e8a5"; }

.icon-inbox:before {
  font-size : 20px;
  color: #000000;
  content: "\e8a6"; }

.icon-indent:before {
  font-size : 20px;
  color: #000000;
  content: "\e8a7"; }

.icon-information-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e8a8"; }

.icon-information:before {
  font-size : 20px;
  color: #000000;
  content: "\e8a9"; }

.icon-instagram:before {
  font-size : 20px;
  color: #000000;
  content: "\e8aa"; }

.icon-instapaper:before {
  font-size : 20px;
  color: #000000;
  content: "\e8ab"; }

.icon-internet-explorer:before {
  font-size : 20px;
  color: #000000;
  content: "\e8ac"; }

.icon-invert-colors:before {
  font-size : 20px;
  color: #000000;
  content: "\e8ad"; }

.icon-jira:before {
  font-size : 20px;
  color: #000000;
  content: "\e8ae"; }

.icon-jsfiddle:before {
  font-size : 20px;
  color: #000000;
  content: "\e8af"; }

.icon-keg:before {
  font-size : 20px;
  color: #000000;
  content: "\e8b0"; }

.icon-key-change:before {
  font-size : 20px;
  color: #000000;
  content: "\e8b1"; }

.icon-key-minus:before {
  font-size : 20px;
  color: #000000;
  content: "\e8b2"; }

.icon-key-plus:before {
  font-size : 20px;
  color: #000000;
  content: "\e8b3"; }

.icon-key-remove:before {
  font-size : 20px;
  color: #000000;
  content: "\e8b4"; }

.icon-key-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\e8b5"; }

.icon-key:before {
  font-size : 20px;
  color: #000000;
  content: "\e8b6"; }

.icon-keyboard-backspace:before {
  font-size : 20px;
  color: #000000;
  content: "\e8b7"; }

.icon-keyboard-caps:before {
  font-size : 20px;
  color: #000000;
  content: "\e8b8"; }

.icon-keyboard-close:before {
  font-size : 20px;
  color: #000000;
  content: "\e8b9"; }

.icon-keyboard-off:before {
  font-size : 20px;
  color: #000000;
  content: "\e8ba"; }

.icon-keyboard-return:before {
  font-size : 20px;
  color: #000000;
  content: "\e8bb"; }

.icon-keyboard-tab:before {
  font-size : 20px;
  color: #000000;
  content: "\e8bc"; }

.icon-keyboard-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\e8bd"; }

.icon-keyboard:before {
  font-size : 20px;
  color: #000000;
  content: "\e8be"; }

.icon-label-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e8bf"; }

.icon-label:before {
  font-size : 20px;
  color: #000000;
  content: "\e8c0"; }

.icon-language-csharp:before {
  font-size : 20px;
  color: #000000;
  content: "\e8c1"; }

.icon-language-css3:before {
  font-size : 20px;
  color: #000000;
  content: "\e8c2"; }

.icon-language-html5:before {
  font-size : 20px;
  color: #000000;
  content: "\e8c3"; }

.icon-language-javascript:before {
  font-size : 20px;
  color: #000000;
  content: "\e8c4"; }

.icon-language-python-text:before {
  font-size : 20px;
  color: #000000;
  content: "\e8c5"; }

.icon-language-python:before {
  font-size : 20px;
  color: #000000;
  content: "\e8c6"; }

.icon-laptop-chromebook:before {
  font-size : 20px;
  color: #000000;
  content: "\e8c7"; }

.icon-laptop-mac:before {
  font-size : 20px;
  color: #000000;
  content: "\e8c8"; }

.icon-laptop-windows:before {
  font-size : 20px;
  color: #000000;
  content: "\e8c9"; }

.icon-laptop:before {
  font-size : 20px;
  color: #000000;
  content: "\e8ca"; }

.icon-lastfm:before {
  font-size : 20px;
  color: #000000;
  content: "\e8cb"; }

.icon-launch:before {
  font-size : 20px;
  color: #000000;
  content: "\e8cc"; }

.icon-layers-off:before {
  font-size : 20px;
  color: #000000;
  content: "\e8cd"; }

.icon-layers:before {
  font-size : 20px;
  color: #000000;
  content: "\e8ce"; }

.icon-leaf:before {
  font-size : 20px;
  color: #000000;
  content: "\e8cf"; }

.icon-library-books:before {
  font-size : 20px;
  color: #000000;
  content: "\e8d0"; }

.icon-library-music:before {
  font-size : 20px;
  color: #000000;
  content: "\e8d1"; }

.icon-library-plus:before {
  font-size : 20px;
  color: #000000;
  content: "\e8d2"; }

.icon-library:before {
  font-size : 20px;
  color: #000000;
  content: "\e8d3"; }

.icon-lightbulb-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e8d4"; }

.icon-lightbulb:before {
  font-size : 20px;
  color: #000000;
  content: "\e8d5"; }

.icon-link-off:before {
  font-size : 20px;
  color: #000000;
  content: "\e8d6"; }

.icon-link-variant-off:before {
  font-size : 20px;
  color: #000000;
  content: "\e8d7"; }

.icon-link-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\e8d8"; }

.icon-link:before {
  font-size : 20px;
  color: #000000;
  content: "\e8d9"; }

.icon-linkedin-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e8da"; }

.icon-linkedin:before {
  font-size : 20px;
  color: #000000;
  content: "\e8db"; }

.icon-linode:before {
  font-size : 20px;
  color: #000000;
  content: "\e8dc"; }

.icon-linux:before {
  font-size : 20px;
  color: #000000;
  content: "\e8dd"; }

.icon-lock-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e8de"; }

.icon-lock-unlocked-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e8df"; }

.icon-lock-unlocked:before {
  font-size : 20px;
  color: #000000;
  content: "\e8e0"; }

.icon-lock:before {
  font-size : 20px;
  color: #000000;
  content: "\e8e1"; }

.icon-login:before {
  font-size : 20px;
  color: #000000;
  content: "\e8e2"; }

.icon-logout:before {
  font-size : 20px;
  color: #000000;
  content: "\e8e3"; }

.icon-looks:before {
  font-size : 20px;
  color: #000000;
  content: "\e8e4"; }

.icon-loupe:before {
  font-size : 20px;
  color: #000000;
  content: "\e8e5"; }

.icon-lumx:before {
  font-size : 20px;
  color: #000000;
  content: "\e8e6"; }

.icon-magnet-on:before {
  font-size : 20px;
  color: #000000;
  content: "\e8e7"; }

.icon-magnet:before {
  font-size : 20px;
  color: #000000;
  content: "\e8e8"; }

.icon-magnify-minus:before {
  font-size : 20px;
  color: #000000;
  content: "\e8e9"; }

.icon-magnify-plus:before {
  font-size : 20px;
  color: #000000;
  content: "\e8ea"; }

.icon-magnify:before {
  font-size : 20px;
  color: #000000;
  content: "\e8eb"; }

.icon-mail-ru:before {
  font-size : 20px;
  color: #000000;
  content: "\e8ec"; }

.icon-map-marker-circle:before {
  font-size : 20px;
  color: #000000;
  content: "\e8ed"; }

.icon-map-marker-multiple:before {
  font-size : 20px;
  color: #000000;
  content: "\e8ee"; }

.icon-map-marker-off:before {
  font-size : 20px;
  color: #000000;
  content: "\e8ef"; }

.icon-map-marker-radius:before {
  font-size : 20px;
  color: #000000;
  content: "\e8f0"; }

.icon-map-marker:before {
  font-size : 20px;
  color: #000000;
  content: "\e8f1"; }

.icon-map:before {
  font-size : 20px;
  color: #000000;
  content: "\e8f2"; }

.icon-margin:before {
  font-size : 20px;
  color: #000000;
  content: "\e8f3"; }

.icon-markdown:before {
  font-size : 20px;
  color: #000000;
  content: "\e8f4"; }

.icon-marker-check:before {
  font-size : 20px;
  color: #000000;
  content: "\e8f5"; }

.icon-martini:before {
  font-size : 20px;
  color: #000000;
  content: "\e8f6"; }

.icon-material-ui:before {
  font-size : 20px;
  color: #000000;
  content: "\e8f7"; }

.icon-maxcdn:before {
  font-size : 20px;
  color: #000000;
  content: "\e8f8"; }

.icon-medium:before {
  font-size : 20px;
  color: #000000;
  content: "\e8f9"; }

.icon-memory:before {
  font-size : 20px;
  color: #000000;
  content: "\e8fa"; }

.icon-menu-down:before {
  font-size : 20px;
  color: #000000;
  content: "\e8fb"; }

.icon-menu-left:before {
  font-size : 20px;
  color: #000000;
  content: "\e8fc"; }

.icon-menu-right:before {
  font-size : 20px;
  color: #000000;
  content: "\e8fd"; }

.icon-menu-up:before {
  font-size : 20px;
  color: #000000;
  content: "\e8fe"; }

.icon-menu:before {
  font-size : 20px;
  color: #000000;
  content: "\e8ff"; }

.icon-message-alert:before {
  font-size : 20px;
  color: #000000;
  content: "\e900"; }

.icon-message-draw:before {
  font-size : 20px;
  color: #000000;
  content: "\e901"; }

.icon-message-image:before {
  font-size : 20px;
  color: #000000;
  content: "\e902"; }

.icon-message-processing:before {
  font-size : 20px;
  color: #000000;
  content: "\e903"; }

.icon-message-reply:before {
  font-size : 20px;
  color: #000000;
  content: "\e904"; }

.icon-message-text-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e905"; }

.icon-message-text:before {
  font-size : 20px;
  color: #000000;
  content: "\e906"; }

.icon-message-video:before {
  font-size : 20px;
  color: #000000;
  content: "\e907"; }

.icon-message:before {
  font-size : 20px;
  color: #000000;
  content: "\e908"; }

.icon-microphone-dots:before {
  font-size : 20px;
  color: #000000;
  content: "\e909"; }

.icon-microphone-off:before {
  font-size : 20px;
  color: #000000;
  content: "\e90a"; }

.icon-microphone-outline {
  background-image: url('./assets/podcasts.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  height: 16px;
  width: 16px; 
  margin-left: 2px;
}
.icon-microphone-outlinewhite {
  background-image: url('./assets/Group 58.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  height: 16px;
  width: 16px; 
  margin-left: 2px;
}

.icon-microphone-settings:before {
  font-size : 20px;
  color: #000000;
  content: "\e90c"; }

.icon-microphone-variant-off:before {
  font-size : 20px;
  color: #000000;
  content: "\e90d"; }

.icon-microphone-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\e90e"; }

.icon-microphone:before {
  font-size : 20px;
  color: #000000;
  content: "\e90f"; }

.icon-minus-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e910"; }

.icon-minus-circle-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e911"; }

.icon-minus-circle:before {
  font-size : 20px;
  color: #000000;
  content: "\e912"; }

.icon-minus-network:before {
  font-size : 20px;
  color: #000000;
  content: "\e913"; }

.icon-minus:before {
  font-size : 20px;
  color: #000000;
  content: "\e914"; }

.icon-monitor-multiple:before {
  font-size : 20px;
  color: #000000;
  content: "\e915"; }

.icon-monitor:before {
  font-size : 20px;
  color: #000000;
  content: "\e916"; }

.icon-more:before {
  font-size : 20px;
  color: #000000;
  content: "\e917"; }

.icon-mouse-off:before {
  font-size : 20px;
  color: #000000;
  content: "\e918"; }

.icon-mouse-variant-off:before {
  font-size : 20px;
  color: #000000;
  content: "\e919"; }

.icon-mouse-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\e91a"; }

.icon-mouse:before {
  font-size : 20px;
  color: #000000;
  content: "\e91b"; }

.icon-movie:before {
  font-size : 20px;
  color: #000000;
  content: "\e91c"; }

.icon-multiplication-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e91d"; }

.icon-multiplication:before {
  font-size : 20px;
  color: #000000;
  content: "\e91e"; }

.icon-music-box-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e91f"; }

.icon-music-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e920"; }

.icon-music-circle:before {
  font-size : 20px;
  color: #000000;
  content: "\e921"; }

.icon-music-note-eighth:before {
  font-size : 20px;
  color: #000000;
  content: "\e922"; }

.icon-music-note-half:before {
  font-size : 20px;
  color: #000000;
  content: "\e923"; }

.icon-music-note-off:before {
  font-size : 20px;
  color: #000000;
  content: "\e924"; }

.icon-music-note-quarter:before {
  font-size : 20px;
  color: #000000;
  content: "\e925"; }

.icon-music-note-sixteenth:before {
  font-size : 20px;
  color: #000000;
  content: "\e926"; }

.icon-music-note-whole:before {
  font-size : 20px;
  color: #000000;
  content: "\e927"; }

.icon-music-note:before {
  font-size : 20px;
  color: #000000;
  content: "\e928"; }

.icon-nature-people:before {
  font-size : 20px;
  color: #000000;
  content: "\e929"; }

.icon-nature:before {
  font-size : 20px;
  color: #000000;
  content: "\e92a"; }

.icon-navigation:before {
  font-size : 20px;
  color: #000000;
  content: "\e92b"; }

.icon-needle:before {
  font-size : 20px;
  color: #000000;
  content: "\e92c"; }

.icon-nest-protect:before {
  font-size : 20px;
  color: #000000;
  content: "\e92d"; }

.icon-nest-thermostat:before {
  font-size : 20px;
  color: #000000;
  content: "\e92e"; }

.icon-newspaper:before {
  font-size : 20px;
  color: #000000;
  content: "\e92f"; }

  .icon-newspaperwhite:before {
    font-size : 20px;
    color: #FFFFFF;
    content: "\e92f"; }
  

.icon-nfc-tap:before {
  font-size : 20px;
  color: #000000;
  content: "\e930"; }

.icon-nfc-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\e931"; }

.icon-nfc:before {
  font-size : 20px;
  color: #000000;
  content: "\e932"; }

.icon-no:before {
  font-size : 20px;
  color: #000000;
  content: "\e933"; }

.icon-not-equal:before {
  font-size : 20px;
  color: #000000;
  content: "\e934"; }

.icon-note-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e935"; }

.icon-note-text:before {
  font-size : 20px;
  color: #000000;
  content: "\e936"; }

.icon-note:before {
  font-size : 20px;
  color: #000000;
  content: "\e937"; }

.icon-numeric-0-box-multiple-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e938"; }

.icon-numeric-0-box-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e939"; }

.icon-numeric-0-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e93a"; }

.icon-numeric-1-box-multiple-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e93b"; }

.icon-numeric-1-box-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e93c"; }

.icon-numeric-1-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e93d"; }

.icon-numeric-2-box-multiple-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e93e"; }

.icon-numeric-2-box-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e93f"; }

.icon-numeric-2-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e940"; }

.icon-numeric-3-box-multiple-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e941"; }

.icon-numeric-3-box-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e942"; }

.icon-numeric-3-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e943"; }

.icon-numeric-4-box-multiple-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e944"; }

.icon-numeric-4-box-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e945"; }

.icon-numeric-4-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e946"; }

.icon-numeric-5-box-multiple-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e947"; }

.icon-numeric-5-box-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e948"; }

.icon-numeric-5-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e949"; }

.icon-numeric-6-box-multiple-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e94a"; }

.icon-numeric-6-box-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e94b"; }

.icon-numeric-6-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e94c"; }

.icon-numeric-7-box-multiple-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e94d"; }

.icon-numeric-7-box-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e94e"; }

.icon-numeric-7-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e94f"; }

.icon-numeric-8-box-multiple-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e950"; }

.icon-numeric-8-box-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e951"; }

.icon-numeric-8-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e952"; }

.icon-numeric-9-box-multiple-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e953"; }

.icon-numeric-9-box-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e954"; }

.icon-numeric-9-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e955"; }

.icon-numeric-9-plus-box-multiple-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e956"; }

.icon-numeric-9-plus-box-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e957"; }

.icon-numeric-9-plus-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e958"; }

.icon-numeric:before {
  font-size : 20px;
  color: #000000;
  content: "\e959"; }

.icon-nutriton:before {
  font-size : 20px;
  color: #000000;
  content: "\e95a"; }

.icon-odnoklassniki:before {
  font-size : 20px;
  color: #000000;
  content: "\e95b"; }

.icon-office:before {
  font-size : 20px;
  color: #000000;
  content: "\e95c"; }

.icon-oil:before {
  font-size : 20px;
  color: #000000;
  content: "\e95d"; }

.icon-omega:before {
  font-size : 20px;
  color: #000000;
  content: "\e95e"; }

.icon-onedrive:before {
  font-size : 20px;
  color: #000000;
  content: "\e95f"; }

.icon-open-in-app:before {
  font-size : 20px;
  color: #000000;
  content: "\e960"; }

.icon-open-in-new:before {
  font-size : 20px;
  color: #000000;
  content: "\e961"; }

.icon-ornament-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\e962"; }

.icon-ornament:before {
  font-size : 20px;
  color: #000000;
  content: "\e963"; }

.icon-outbox:before {
  font-size : 20px;
  color: #000000;
  content: "\e964"; }

.icon-outdent:before {
  font-size : 20px;
  color: #000000;
  content: "\e965"; }

.icon-owl:before {
  font-size : 20px;
  color: #000000;
  content: "\e966"; }

.icon-package-down:before {
  font-size : 20px;
  color: #000000;
  content: "\e967"; }

.icon-package-up:before {
  font-size : 20px;
  color: #000000;
  content: "\e968"; }

.icon-package-variant-closed:before {
  font-size : 20px;
  color: #000000;
  content: "\e969"; }

.icon-package-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\e96a"; }

.icon-package:before {
  font-size : 20px;
  color: #000000;
  content: "\e96b"; }

.icon-palette-advanced:before {
  font-size : 20px;
  color: #000000;
  content: "\e96c"; }

.icon-palette {
  background-image: url('./assets/skin.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  height: 16px;
  width: 16px; 
  margin-left: 2px;
}
.icon-palettewhite {
  background-image: url('./assets/palette.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  height: 16px;
  width: 16px; 
  margin-left: 2px;
}
.icon-panda:before {
  font-size : 20px;
  color: #000000;
  content: "\e96e"; }

.icon-pandora:before {
  font-size : 20px;
  color: #000000;
  content: "\e96f"; }

.icon-panorama-fisheye:before {
  font-size : 20px;
  color: #000000;
  content: "\e970"; }

.icon-panorama-horizontal:before {
  font-size : 20px;
  color: #000000;
  content: "\e971"; }

.icon-panorama-vertical:before {
  font-size : 20px;
  color: #000000;
  content: "\e972"; }

.icon-panorama-wide-angle:before {
  font-size : 20px;
  color: #000000;
  content: "\e973"; }

.icon-panorama:before {
  font-size : 20px;
  color: #000000;
  content: "\e974"; }

.icon-paper-cut-vertical:before {
  font-size : 20px;
  color: #000000;
  content: "\e975"; }

.icon-paperclip:before {
  font-size : 20px;
  color: #000000;
  content: "\e976"; }

.icon-parking:before {
  font-size : 20px;
  color: #000000;
  content: "\e977"; }

.icon-pause-circle-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e978"; }

.icon-pause-circle:before {
  font-size : 20px;
  color: #000000;
  content: "\e979"; }

.icon-pause-octagon-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e97a"; }

.icon-pause-octagon:before {
  font-size : 20px;
  color: #000000;
  content: "\e97b"; }

.icon-pause:before {
  font-size : 20px;
  color: #000000;
  content: "\e97c"; }

.icon-paw:before {
  font-size : 20px;
  color: #000000;
  content: "\e97d"; }

.icon-pen:before {
  font-size : 20px;
  color: #000000;
  content: "\e97e"; }

.icon-pencil-box-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e97f"; }

.icon-pencil-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e980"; }

.icon-pencil:before {
  font-size : 20px;
  color: #000000;
  content: "\e981"; }

.icon-people:before {
  font-size : 20px;
  color: #000000;
  content: "\e982"; }

.icon-percent:before {
  font-size : 20px;
  color: #000000;
  content: "\e983"; }

.icon-person-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e984"; }

.icon-person-minus:before {
  font-size : 20px;
  color: #000000;
  content: "\e985"; }

.icon-person-plus:before {
  font-size : 20px;
  color: #000000;
  content: "\e986"; }

.icon-pharmacy:before {
  font-size : 20px;
  color: #000000;
  content: "\e987"; }

.icon-phone-bluetooth:before {
  font-size : 20px;
  color: #000000;
  content: "\e988"; }

.icon-phone-dots:before {
  font-size : 20px;
  color: #000000;
  content: "\e989"; }

.icon-phone-forward:before {
  font-size : 20px;
  color: #000000;
  content: "\e98a"; }

.icon-phone-hangup:before {
  font-size : 20px;
  color: #000000;
  content: "\e98b"; }

.icon-phone-in-talk:before {
  font-size : 20px;
  color: #000000;
  content: "\e98c"; }

.icon-phone-incoming:before {
  font-size : 20px;
  color: #000000;
  content: "\e98d"; }

.icon-phone-locked:before {
  font-size : 20px;
  color: #000000;
  content: "\e98e"; }

.icon-phone-log:before {
  font-size : 20px;
  color: #000000;
  content: "\e98f"; }

.icon-phone-missed:before {
  font-size : 20px;
  color: #000000;
  content: "\e990"; }

.icon-phone-outgoing:before {
  font-size : 20px;
  color: #000000;
  content: "\e991"; }

.icon-phone-paused:before {
  font-size : 20px;
  color: #000000;
  content: "\e992"; }

.icon-phone:before {
  font-size : 20px;
  color: #000000;
  content: "\e993"; }

.icon-picture:before {
  font-size : 20px;
  color: #000000;
  content: "\e994"; }

.icon-pig:before {
  font-size : 20px;
  color: #000000;
  content: "\e995"; }

.icon-pill:before {
  font-size : 20px;
  color: #000000;
  content: "\e996"; }

.icon-pin-off:before {
  font-size : 20px;
  color: #000000;
  content: "\e997"; }

.icon-pin:before {
  font-size : 20px;
  color: #000000;
  content: "\e998"; }

.icon-pine-tree-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e999"; }

.icon-pine-tree:before {
  font-size : 20px;
  color: #000000;
  content: "\e99a"; }

.icon-pinterest-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e99b"; }

.icon-pinterest:before {
  font-size : 20px;
  color: #000000;
  content: "\e99c"; }

.icon-pizza:before {
  font-size : 20px;
  color: #000000;
  content: "\e99d"; }

.icon-plane:before {
  font-size : 20px;
  color: #000000;
  content: "\e99e"; }

.icon-play-box-outline {
  background-image: url('./assets/exportvideo.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  height: 16px;
  width: 16px; 
  margin-left: 2px;
}

.icon-play-circle-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e9a0"; }

.icon-play-circle:before {
  font-size : 20px;
  color: #000000;
  content: "\e9a1"; }

.icon-play:before {
  font-size : 20px;
  color: #000000;
  content: "\e9a2"; }

.icon-playlist-minus:before {
  font-size : 20px;
  color: #000000;
  content: "\e9a3"; }

.icon-playlist-plus:before {
  font-size : 20px;
  color: #000000;
  content: "\e9a4"; }

.icon-playstation:before {
  font-size : 20px;
  color: #000000;
  content: "\e9a5"; }

.icon-plus-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e9a6"; }

.icon-plus-circle-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\e9a7"; }

.icon-plus-circle:before {
  font-size : 20px;
  color: #000000;
  content: "\e9a8"; }

.icon-plus-network:before {
  font-size : 20px;
  color: #000000;
  content: "\e9a9"; }

.icon-plus-one:before {
  font-size : 20px;
  color: #000000;
  content: "\e9aa"; }

.icon-plus:before {
  font-size : 20px;
  color: #000000;
  content: "\e9ab"; }

.icon-pocket:before {
  font-size : 20px;
  color: #000000;
  content: "\e9ac"; }

.icon-poll-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e9ad"; }

.icon-poll:before {
  font-size : 20px;
  color: #000000;
  content: "\e9ae"; }

.icon-polymer:before {
  font-size : 20px;
  color: #000000;
  content: "\e9af"; }

.icon-popcorn:before {
  font-size : 20px;
  color: #000000;
  content: "\e9b0"; }

.icon-pound-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e9b1"; }

.icon-pound:before {
  font-size : 20px;
  color: #000000;
  content: "\e9b2"; }

.icon-power-settings:before {
  font-size : 20px;
  color: #000000;
  content: "\e9b3"; }

.icon-power-socket:before {
  font-size : 20px;
  color: #000000;
  content: "\e9b4"; }

.icon-power:before {
  font-size : 20px;
  color: #000000;
  content: "\e9b5"; }

.icon-presentation-play:before {
  font-size : 20px;
  color: #000000;
  content: "\e9b6"; }

.icon-presentation:before {
  font-size : 20px;
  color: #000000;
  content: "\e9b7"; }

.icon-printer-3d:before {
  font-size : 20px;
  color: #000000;
  content: "\e9b8"; }

.icon-printer:before {
  font-size : 20px;
  color: #000000;
  content: "\e9b9"; }

.icon-pulse:before {
  font-size : 20px;
  color: #000000;
  content: "\e9ba"; }

.icon-puzzle:before {
  font-size : 20px;
  color: #000000;
  content: "\e9bb"; }

.icon-qrcode:before {
  font-size : 20px;
  color: #000000;
  content: "\e9bc"; }

.icon-quadcopter:before {
  font-size : 20px;
  color: #000000;
  content: "\e9bd"; }

.icon-quality-high:before {
  font-size : 20px;
  color: #000000;
  content: "\e9be"; }

.icon-question-mark-circle:before {
  font-size : 20px;
  color: #000000;
  content: "\e9bf"; }

.icon-quick-reply:before {
  font-size : 20px;
  color: #000000;
  content: "\e9c0"; }

.icon-quicktime:before {
  font-size : 20px;
  color: #000000;
  content: "\e9c1"; }

.icon-rabbit:before {
  font-size : 20px;
  color: #000000;
  content: "\e9c2"; }

.icon-radiator:before {
  font-size : 20px;
  color: #000000;
  content: "\e9c3"; }

.icon-radio-tower:before {
  font-size : 20px;
  color: #000000;
  content: "\e9c4"; }

.icon-radio:before {
  font-size : 20px;
  color: #000000;
  content: "\e9c5"; }

.icon-radioactive:before {
  font-size : 20px;
  color: #000000;
  content: "\e9c6"; }

.icon-radiobox-blank:before {
  font-size : 20px;
  color: #000000;
  content: "\e9c7"; }

.icon-radiobox-marked:before {
  font-size : 20px;
  color: #000000;
  content: "\e9c8"; }

.icon-raspberrypi:before {
  font-size : 20px;
  color: #000000;
  content: "\e9c9"; }

.icon-rdio:before {
  font-size : 20px;
  color: #000000;
  content: "\e9ca"; }

.icon-read:before {
  font-size : 20px;
  color: #000000;
  content: "\e9cb"; }

.icon-readability:before {
  font-size : 20px;
  color: #000000;
  content: "\e9cc"; }

.icon-receipt:before {
  font-size : 20px;
  color: #000000;
  content: "\e9cd"; }

.icon-recycle:before {
  font-size : 20px;
  color: #000000;
  content: "\e9ce"; }

.icon-reddit:before {
  font-size : 20px;
  color: #000000;
  content: "\e9cf"; }

.icon-redo-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\e9d0"; }

.icon-redo:before {
  font-size : 20px;
  color: #000000;
  content: "\e9d1"; }

.icon-refresh:before {
  font-size : 20px;
  color: #000000;
  content: "\e9d2"; }

.icon-relative-scale:before {
  font-size : 20px;
  color: #000000;
  content: "\e9d3"; }

.icon-reload:before {
  font-size : 20px;
  color: #000000;
  content: "\e9d4"; }

.icon-remote:before {
  font-size : 20px;
  color: #000000;
  content: "\e9d5"; }

.icon-rename-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e9d6"; }

.icon-repeat-off:before {
  font-size : 20px;
  color: #000000;
  content: "\e9d7"; }

.icon-repeat-once:before {
  font-size : 20px;
  color: #000000;
  content: "\e9d8"; }

.icon-repeat:before {
  font-size : 20px;
  color: #000000;
  content: "\e9d9"; }

.icon-replay:before {
  font-size : 20px;
  color: #000000;
  content: "\e9da"; }

.icon-reply-all:before {
  font-size : 20px;
  color: #000000;
  content: "\e9db"; }

.icon-reply:before {
  font-size : 20px;
  color: #000000;
  content: "\e9dc"; }

.icon-reproduction:before {
  font-size : 20px;
  color: #000000;
  content: "\e9dd"; }

.icon-resize-bottom-right:before {
  font-size : 20px;
  color: #000000;
  content: "\e9de"; }

.icon-responsive:before {
  font-size : 20px;
  color: #000000;
  content: "\e9df"; }

.icon-restore:before {
  font-size : 20px;
  color: #000000;
  content: "\e9e0"; }

.icon-rewind:before {
  font-size : 20px;
  color: #000000;
  content: "\e9e1"; }

.icon-ribbon:before {
  font-size : 20px;
  color: #000000;
  content: "\e9e2"; }

.icon-road:before {
  font-size : 20px;
  color: #000000;
  content: "\e9e3"; }

.icon-rocket:before {
  font-size : 20px;
  color: #000000;
  content: "\e9e4"; }

.icon-rotate-3d:before {
  font-size : 20px;
  color: #000000;
  content: "\e9e5"; }

.icon-rotate-left-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\e9e6"; }

.icon-rotate-left:before {
  font-size : 20px;
  color: #000000;
  content: "\e9e7"; }

.icon-rotate-right-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\e9e8"; }

.icon-rotate-right:before {
  font-size : 20px;
  color: #000000;
  content: "\e9e9"; }

.icon-routes:before {
  font-size : 20px;
  color: #000000;
  content: "\e9ea"; }

.icon-rss-box:before {
  font-size : 20px;
  color: #000000;
  content: "\e9eb"; }

.icon-ruler:before {
  font-size : 20px;
  color: #000000;
  content: "\e9ec"; }

.icon-run:before {
  font-size : 20px;
  color: #000000;
  content: "\e9ed"; }

.icon-sale:before {
  font-size : 20px;
  color: #000000;
  content: "\e9ee"; }

.icon-satellite-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\e9ef"; }

.icon-satellite:before {
  font-size : 20px;
  color: #000000;
  content: "\e9f0"; }

.icon-scale-bathroom:before {
  font-size : 20px;
  color: #000000;
  content: "\e9f1"; }

.icon-scale:before {
  font-size : 20px;
  color: #000000;
  content: "\e9f2"; }

.icon-school:before {
  font-size : 20px;
  color: #000000;
  content: "\e9f3"; }

.icon-screen-rotation-lock:before {
  font-size : 20px;
  color: #000000;
  content: "\e9f4"; }

.icon-screen-rotation:before {
  font-size : 20px;
  color: #000000;
  content: "\e9f5"; }

.icon-script:before {
  font-size : 20px;
  color: #000000;
  content: "\e9f6"; }

.icon-security-network:before {
  font-size : 20px;
  color: #000000;
  content: "\e9f7"; }

.icon-security:before {
  font-size : 20px;
  color: #000000;
  content: "\e9f8"; }

.icon-select-all:before {
  font-size : 20px;
  color: #000000;
  content: "\e9f9"; }

.icon-select-inverse:before {
  font-size : 20px;
  color: #000000;
  content: "\e9fa"; }

.icon-select-off:before {
  font-size : 20px;
  color: #000000;
  content: "\e9fb"; }

.icon-select:before {
  font-size : 20px;
  color: #000000;
  content: "\e9fc"; }

.icon-send:before {
  font-size : 20px;
  color: #000000;
  content: "\e9fd"; }

.icon-server-minus:before {
  font-size : 20px;
  color: #000000;
  content: "\e9fe"; }

.icon-server-network-off:before {
  font-size : 20px;
  color: #000000;
  content: "\e9ff"; }

.icon-server-network:before {
  font-size : 20px;
  color: #000000;
  content: "\ea00"; }

.icon-server-off:before {
  font-size : 20px;
  color: #000000;
  content: "\ea01"; }

.icon-server-plus:before {
  font-size : 20px;
  color: #000000;
  content: "\ea02"; }

.icon-server-remove:before {
  font-size : 20px;
  color: #000000;
  content: "\ea03"; }

.icon-server-security:before {
  font-size : 20px;
  color: #000000;
  content: "\ea04"; }

.icon-server:before {
  font-size : 20px;
  color: #000000;
  content: "\ea05"; }

.icon-shape-plus:before {
  font-size : 20px;
  color: #000000;
  content: "\ea06"; }

.icon-share-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\ea07"; }

.icon-share:before {
  font-size : 20px;
  color: #000000;
  content: "\ea08"; }

.icon-shield-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\ea09"; }

.icon-shield:before {
  font-size : 20px;
  color: #000000;
  content: "\ea0a"; }

.icon-shopping-music:before {
  font-size : 20px;
  color: #000000;
  content: "\ea0b"; }

.icon-shopping:before {
  font-size : 20px;
  color: #000000;
  content: "\ea0c"; }

.icon-shuffle:before {
  font-size : 20px;
  color: #000000;
  content: "\ea0d"; }

.icon-sigma:before {
  font-size : 20px;
  color: #000000;
  content: "\ea0e"; }

.icon-sign-caution:before {
  font-size : 20px;
  color: #000000;
  content: "\ea0f"; }

.icon-signal:before {
  font-size : 20px;
  color: #000000;
  content: "\ea10"; }

.icon-silverware-fork:before {
  font-size : 20px;
  color: #000000;
  content: "\ea11"; }

.icon-silverware-spoon:before {
  font-size : 20px;
  color: #000000;
  content: "\ea12"; }

.icon-silverware-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\ea13"; }

.icon-silverware:before {
  font-size : 20px;
  color: #000000;
  content: "\ea14"; }

.icon-sim-alert:before {
  font-size : 20px;
  color: #000000;
  content: "\ea15"; }

.icon-sim:before {
  font-size : 20px;
  color: #000000;
  content: "\ea16"; }

.icon-sitemap:before {
  font-size : 20px;
  color: #000000;
  content: "\ea17"; }

.icon-skip-next:before {
  font-size : 20px;
  color: #000000;
  content: "\ea18"; }

.icon-skip-previous:before {
  font-size : 20px;
  color: #000000;
  content: "\ea19"; }

.icon-skype-business:before {
  font-size : 20px;
  color: #000000;
  content: "\ea1a"; }

.icon-skype:before {
  font-size : 20px;
  color: #000000;
  content: "\ea1b"; }

.icon-sleep-off:before {
  font-size : 20px;
  color: #000000;
  content: "\ea1c"; }

.icon-sleep:before {
  font-size : 20px;
  color: #000000;
  content: "\ea1d"; }

.icon-smoking-off:before {
  font-size : 20px;
  color: #000000;
  content: "\ea1e"; }

.icon-smoking:before {
  font-size : 20px;
  color: #000000;
  content: "\ea1f"; }

.icon-snapchat:before {
  font-size : 20px;
  color: #000000;
  content: "\ea20"; }

.icon-snowman:before {
  font-size : 20px;
  color: #000000;
  content: "\ea21"; }

.icon-sofa:before {
  font-size : 20px;
  color: #000000;
  content: "\ea22"; }

.icon-sort-alphabetical:before {
  font-size : 20px;
  color: #000000;
  content: "\ea23"; }

.icon-sort-ascending:before {
  font-size : 20px;
  color: #000000;
  content: "\ea24"; }

.icon-sort-descending:before {
  font-size : 20px;
  color: #000000;
  content: "\ea25"; }

.icon-sort-numeric:before {
  font-size : 20px;
  color: #000000;
  content: "\ea26"; }

.icon-sort-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\ea27"; }

.icon-sort:before {
  font-size : 20px;
  color: #000000;
  content: "\ea28"; }

.icon-soundcloud:before {
  font-size : 20px;
  color: #000000;
  content: "\ea29"; }

.icon-source-fork:before {
  font-size : 20px;
  color: #000000;
  content: "\ea2a"; }

.icon-source-pull:before {
  font-size : 20px;
  color: #000000;
  content: "\ea2b"; }

.icon-speaker-off:before {
  font-size : 20px;
  color: #000000;
  content: "\ea2c"; }

.icon-speaker:before {
  font-size : 20px;
  color: #000000;
  content: "\ea2d"; }

.icon-speedometer:before {
  font-size : 20px;
  color: #000000;
  content: "\ea2e"; }

.icon-spellcheck:before {
  font-size : 20px;
  color: #000000;
  content: "\ea2f"; }

.icon-spotify:before {
  font-size : 20px;
  color: #000000;
  content: "\ea30"; }

.icon-spotlight-beam:before {
  font-size : 20px;
  color: #000000;
  content: "\ea31"; }

.icon-spotlight:before {
  font-size : 20px;
  color: #000000;
  content: "\ea32"; }

.icon-spreadsheet:before {
  font-size : 20px;
  color: #000000;
  content: "\ea33"; }

.icon-square-inc-cash:before {
  font-size : 20px;
  color: #000000;
  content: "\ea34"; }

.icon-square-inc:before {
  font-size : 20px;
  color: #000000;
  content: "\ea35"; }

.icon-stackoverflow:before {
  font-size : 20px;
  color: #000000;
  content: "\ea36"; }

.icon-star-circle:before {
  font-size : 20px;
  color: #000000;
  content: "\ea37"; }

.icon-star-half:before {
  font-size : 20px;
  color: #000000;
  content: "\ea38"; }

.icon-star-of-david:before {
  font-size : 20px;
  color: #000000;
  content: "\ea39"; }

.icon-star-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\ea3a"; }

.icon-star:before {
  font-size : 20px;
  color: #000000;
  content: "\ea3b"; }

.icon-steam:before {
  font-size : 20px;
  color: #000000;
  content: "\ea3c"; }

.icon-stethoscope:before {
  font-size : 20px;
  color: #000000;
  content: "\ea3d"; }

.icon-stocking:before {
  font-size : 20px;
  color: #000000;
  content: "\ea3e"; }

.icon-stop:before {
  font-size : 20px;
  color: #000000;
  content: "\ea3f"; }

.icon-store-24-hour:before {
  font-size : 20px;
  color: #000000;
  content: "\ea40"; }

.icon-store:before {
  font-size : 20px;
  color: #000000;
  content: "\ea41"; }

.icon-stove:before {
  font-size : 20px;
  color: #000000;
  content: "\ea42"; }

.icon-subway:before {
  font-size : 20px;
  color: #000000;
  content: "\ea43"; }

.icon-sunglasses:before {
  font-size : 20px;
  color: #000000;
  content: "\ea44"; }

.icon-swap-horizontal:before {
  font-size : 20px;
  color: #000000;
  content: "\ea45"; }

.icon-swap-vertical:before {
  font-size : 20px;
  color: #000000;
  content: "\ea46"; }

.icon-swim:before {
  font-size : 20px;
  color: #000000;
  content: "\ea47"; }

.icon-sword:before {
  font-size : 20px;
  color: #000000;
  content: "\ea48"; }

.icon-sync-alert:before {
  font-size : 20px;
  color: #000000;
  content: "\ea49"; }

.icon-sync-off:before {
  font-size : 20px;
  color: #000000;
  content: "\ea4a"; }

.icon-sync:before {
  font-size : 20px;
  color: #000000;
  content: "\ea4b"; }

.icon-tab-unselected:before {
  font-size : 20px;
  color: #000000;
  content: "\ea4c"; }

.icon-tab:before {
  font-size : 20px;
  color: #000000;
  content: "\ea4d"; }

.icon-table-column-plus-after:before {
  font-size : 20px;
  color: #000000;
  content: "\ea4e"; }

.icon-table-column-plus-before:before {
  font-size : 20px;
  color: #000000;
  content: "\ea4f"; }

.icon-table-column-remove:before {
  font-size : 20px;
  color: #000000;
  content: "\ea50"; }

.icon-table-column-width:before {
  font-size : 20px;
  color: #000000;
  content: "\ea51"; }

.icon-table-edit:before {
  font-size : 20px;
  color: #000000;
  content: "\ea52"; }

.icon-table-large:before {
  font-size : 20px;
  color: #000000;
  content: "\ea53"; }

.icon-table-row-height:before {
  font-size : 20px;
  color: #000000;
  content: "\ea54"; }

.icon-table-row-plus-after:before {
  font-size : 20px;
  color: #000000;
  content: "\ea55"; }

.icon-table-row-plus-before:before {
  font-size : 20px;
  color: #000000;
  content: "\ea56"; }

.icon-table-row-remove:before {
  font-size : 20px;
  color: #000000;
  content: "\ea57"; }

.icon-table:before {
  font-size : 20px;
  color: #000000;
  content: "\ea58"; }

.icon-tablet-android:before {
  font-size : 20px;
  color: #000000;
  content: "\ea59"; }

.icon-tablet-ipad:before {
  font-size : 20px;
  color: #000000;
  content: "\ea5a"; }

.icon-tablet:before {
  font-size : 20px;
  color: #000000;
  content: "\ea5b"; }

.icon-tag-faces:before {
  font-size : 20px;
  color: #000000;
  content: "\ea5c"; }

.icon-tag-multiple:before {
  font-size : 20px;
  color: #000000;
  content: "\ea5d"; }

.icon-tag-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\ea5e"; }

.icon-tag-text-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\ea5f"; }

.icon-tag:before {
  font-size : 20px;
  color: #000000;
  content: "\ea60"; }

.icon-taxi:before {
  font-size : 20px;
  color: #000000;
  content: "\ea61"; }

.icon-teamviewer:before {
  font-size : 20px;
  color: #000000;
  content: "\ea62"; }

.icon-telegram:before {
  font-size : 20px;
  color: #000000;
  content: "\ea63"; }

.icon-television-guide:before {
  font-size : 20px;
  color: #000000;
  content: "\ea64"; }

.icon-television:before {
  font-size : 20px;
  color: #000000;
  content: "\ea65"; }

.icon-temperature-celsius:before {
  font-size : 20px;
  color: #000000;
  content: "\ea66"; }

.icon-temperature-fahrenheit:before {
  font-size : 20px;
  color: #000000;
  content: "\ea67"; }

.icon-temperature-kelvin:before {
  font-size : 20px;
  color: #000000;
  content: "\ea68"; }

.icon-tennis:before {
  font-size : 20px;
  color: #000000;
  content: "\ea69"; }

.icon-tent:before {
  font-size : 20px;
  color: #000000;
  content: "\ea6a"; }

.icon-terrain:before {
  font-size : 20px;
  color: #000000;
  content: "\ea6b"; }

.icon-text-to-speech-off:before {
  font-size : 20px;
  color: #000000;
  content: "\ea6c"; }

.icon-text-to-speech:before {
  font-size : 20px;
  color: #000000;
  content: "\ea6d"; }

.icon-texture:before {
  font-size : 20px;
  color: #000000;
  content: "\ea6e"; }

.icon-theater:before {
  font-size : 20px;
  color: #000000;
  content: "\ea6f"; }

.icon-theme-light-dark:before {
  font-size : 20px;
  color: #000000;
  content: "\ea70"; }

.icon-thermometer-lines:before {
  font-size : 20px;
  color: #000000;
  content: "\ea71"; }

.icon-thermometer:before {
  font-size : 20px;
  color: #000000;
  content: "\ea72"; }

.icon-thumb-down-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\ea73"; }

.icon-thumb-down:before {
  font-size : 20px;
  color: #000000;
  content: "\ea74"; }

.icon-thumb-up-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\ea75"; }

.icon-thumb-up:before {
  font-size : 20px;
  color: #000000;
  content: "\ea76"; }

.icon-thumbs-up-down:before {
  font-size : 20px;
  color: #000000;
  content: "\ea77"; }

.icon-ticket-account:before {
  font-size : 20px;
  color: #000000;
  content: "\ea78"; }

.icon-ticket:before {
  font-size : 20px;
  color: #000000;
  content: "\ea79"; }

.icon-tie:before {
  font-size : 20px;
  color: #000000;
  content: "\ea7a"; }

.icon-tile-four {
  background-image: url('./assets/dahsbord.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  height: 16px;
  width: 16px; 
  margin-left: 2px;
}
// .bbbobbb {
//   background-image: url('./assets/dahsbord.svg');
//   background-size: contain;
//   background-repeat: no-repeat;
//   background-position-x: center;
//   height: 16px;
//   width: 16px; 
//   margin-left: 2px;
// }
.icon-tile-fourwhite {
  background-image: url('./assets/appstore.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  height: 16px;
  width: 16px; 
  margin-left: 2px;
}

.icon-timelapse:before {
  font-size : 20px;
  color: #000000;
  content: "\ea7c"; }

.icon-timer-3:before {
  font-size : 20px;
  color: #000000;
  content: "\ea7d"; }

.icon-timer-10:before {
  font-size : 20px;
  color: #000000;
  content: "\ea7e"; }

.icon-timer-off:before {
  font-size : 20px;
  color: #000000;
  content: "\ea7f"; }

.icon-timer-sand:before {
  font-size : 20px;
  color: #000000;
  content: "\ea80"; }

.icon-timer:before {
  font-size : 20px;
  color: #000000;
  content: "\ea81"; }

.icon-timetable:before {
  font-size : 20px;
  color: #000000;
  content: "\ea82"; }

.icon-toggle-switch-off:before {
  font-size : 20px;
  color: #000000;
  content: "\ea83"; }

.icon-toggle-switch-on:before {
  font-size : 20px;
  color: #000000;
  content: "\ea84"; }

.icon-tooltip-edit:before {
  font-size : 20px;
  color: #000000;
  content: "\ea85"; }

.icon-tooltip-image:before {
  font-size : 20px;
  color: #000000;
  content: "\ea86"; }

.icon-tooltip-outline-plus:before {
  font-size : 20px;
  color: #000000;
  content: "\ea87"; }

.icon-tooltip-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\ea88"; }

.icon-tooltip-text:before {
  font-size : 20px;
  color: #000000;
  content: "\ea89"; }

.icon-tooltip:before {
  font-size : 20px;
  color: #000000;
  content: "\ea8a"; }

.icon-tor:before {
  font-size : 20px;
  color: #000000;
  content: "\ea8b"; }

.icon-traffic-light:before {
  font-size : 20px;
  color: #000000;
  content: "\ea8c"; }

.icon-train:before {
  font-size : 20px;
  color: #000000;
  content: "\ea8d"; }

.icon-tram:before {
  font-size : 20px;
  color: #000000;
  content: "\ea8e"; }

.icon-transcribe-close:before {
  font-size : 20px;
  color: #000000;
  content: "\ea8f"; }

.icon-transcribe:before {
  font-size : 20px;
  color: #000000;
  content: "\ea90"; }

.icon-transfer:before {
  font-size : 20px;
  color: #000000;
  content: "\ea91"; }

.icon-trash:before {
  font-size : 20px;
  color: #000000;
  content: "\ea92"; }

.icon-tree:before {
  font-size : 20px;
  color: #000000;
  content: "\ea93"; }

.icon-trello:before {
  font-size : 20px;
  color: #000000;
  content: "\ea94"; }

.icon-trending-down:before {
  font-size : 20px;
  color: #000000;
  content: "\ea95"; }

.icon-trending-neutral:before {
  font-size : 20px;
  color: #000000;
  content: "\ea96"; }

.icon-trending-up:before {
  font-size : 20px;
  color: #000000;
  content: "\ea97"; }

.icon-truck:before {
  font-size : 20px;
  color: #000000;
  content: "\ea98"; }

.icon-tshirt-crew:before {
  font-size : 20px;
  color: #000000;
  content: "\ea99"; }

.icon-tshirt-v:before {
  font-size : 20px;
  color: #000000;
  content: "\ea9a"; }

.icon-tumblr-reblog:before {
  font-size : 20px;
  color: #000000;
  content: "\ea9b"; }

.icon-tumblr:before {
  font-size : 20px;
  color: #000000;
  content: "\ea9c"; }

.icon-twitch:before {
  font-size : 20px;
  color: #000000;
  content: "\ea9d"; }

.icon-twitter-box:before {
  font-size : 20px;
  color: #000000;
  content: "\ea9e"; }

.icon-twitter-circle:before {
  font-size : 20px;
  color: #000000;
  content: "\ea9f"; }

.icon-twitter-retweet:before {
  font-size : 20px;
  color: #000000;
  content: "\eaa0"; }

.icon-twitter:before {
  font-size : 20px;
  color: #000000;
  content: "\eaa1"; }

.icon-ubuntu:before {
  font-size : 20px;
  color: #000000;
  content: "\eaa2"; }

.icon-umbrella-outline:before {
  font-size : 20px;
  color: #000000;
  content: "\eaa3"; }

.icon-umbrella:before {
  font-size : 20px;
  color: #000000;
  content: "\eaa4"; }

.icon-undo-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\eaa5"; }

.icon-undo:before {
  font-size : 20px;
  color: #000000;
  content: "\eaa6"; }

.icon-unfold-less:before {
  font-size : 20px;
  color: #000000;
  content: "\eaa7"; }

.icon-unfold-more:before {
  font-size : 20px;
  color: #000000;
  content: "\eaa8"; }

.icon-ungroup:before {
  font-size : 20px;
  color: #000000;
  content: "\eaa9"; }

.icon-untappd:before {
  font-size : 20px;
  color: #000000;
  content: "\eaaa"; }

.icon-upload:before {
  font-size : 20px;
  color: #000000;
  content: "\eaab"; }

.icon-usb:before {
  font-size : 20px;
  color: #000000;
  content: "\eaac"; }

.icon-vector-curve:before {
  font-size : 20px;
  color: #000000;
  content: "\eaad"; }

.icon-vector-point:before {
  font-size : 20px;
  color: #000000;
  content: "\eaae"; }

.icon-vector-square:before {
  font-size : 20px;
  color: #000000;
  content: "\eaaf"; }

.icon-verified:before {
  font-size : 20px;
  color: #000000;
  content: "\eab0"; }

.icon-vibration:before {
  font-size : 20px;
  color: #000000;
  content: "\eab1"; }

.icon-video-off:before {
  font-size : 20px;
  color: #000000;
  content: "\eab2"; }

.icon-video-switch:before {
  font-size : 20px;
  color: #000000;
  content: "\eab3"; }

.icon-video:before {
  font-size : 20px;
  color: #000000;
  content: "\eab4"; }

  .icon-videowhite {
    background-image: url('./assets/video-camera.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    height: 16px;
    width: 16px; 
    margin-left: 2px;
  }


.icon-view-agenda:before {
  font-size : 20px;
  color: #000000;
  content: "\eab5"; }

.icon-view-array:before {
  font-size : 20px;
  color: #000000;
  content: "\eab6"; }

.icon-view-carousel {
  background-image: url('./assets/segment.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  height: 16px;
  width: 16px; 
  margin-left: 2px;
}

.icon-view-column:before {
  font-size : 20px;
  color: #000000;
  content: "\eab8"; }

.icon-view-dashboard:before {
  font-size : 20px;
  color: #000000;
  content: "\eab9"; }

.icon-view-day:before {
  font-size : 20px;
  color: #000000;
  content: "\eaba"; }

.icon-view-headline:before {
  font-size : 20px;
  color: #000000;
  content: "\eabb"; }

.icon-view-list:before {
  font-size : 20px;
  color: #000000;
  content: "\eabc"; }

.icon-view-module:before {
  font-size : 20px;
  color: #000000;
  content: "\eabd"; }

.icon-view-quilt:before {
  font-size : 20px;
  color: #000000;
  content: "\eabe"; }

.icon-view-stream:before {
  font-size : 20px;
  color: #000000;
  content: "\eabf"; }

.icon-view-week:before {
  font-size : 20px;
  color: #000000;
  content: "\eac0"; }

.icon-vimeo:before {
  font-size : 20px;
  color: #000000;
  content: "\eac1"; }

.icon-vine:before {
  font-size : 20px;
  color: #000000;
  content: "\eac2"; }

.icon-vk-box:before {
  font-size : 20px;
  color: #000000;
  content: "\eac3"; }

.icon-vk-circle:before {
  font-size : 20px;
  color: #000000;
  content: "\eac4"; }

.icon-vk:before {
  font-size : 20px;
  color: #000000;
  content: "\eac5"; }

.icon-voicemail:before {
  font-size : 20px;
  color: #000000;
  content: "\eac6"; }

.icon-volume-high:before {
  font-size : 20px;
  color: #000000;
  content: "\eac7"; }

.icon-volume-low:before {
  font-size : 20px;
  color: #000000;
  content: "\eac8"; }

.icon-volume-medium:before {
  font-size : 20px;
  color: #000000;
  content: "\eac9"; }

.icon-volume-off:before {
  font-size : 20px;
  color: #000000;
  content: "\eaca"; }

.icon-volume:before {
  font-size : 20px;
  color: #000000;
  content: "\eacb"; }

.icon-vpn:before {
  font-size : 20px;
  color: #000000;
  content: "\eacc"; }

.icon-walk:before {
  font-size : 20px;
  color: #000000;
  content: "\eacd"; }

.icon-wallet-giftcard:before {
  font-size : 20px;
  color: #000000;
  content: "\eace"; }

.icon-wallet-membership:before {
  font-size : 20px;
  color: #000000;
  content: "\eacf"; }

.icon-wallet-travel:before {
  font-size : 20px;
  color: #000000;
  content: "\ead0"; }

.icon-wallet:before {
  font-size : 20px;
  color: #000000;
  content: "\ead1"; }

.icon-washing-machine:before {
  font-size : 20px;
  color: #000000;
  content: "\ead2"; }

.icon-watch-export:before {
  font-size : 20px;
  color: #000000;
  content: "\ead3"; }

.icon-watch-import:before {
  font-size : 20px;
  color: #000000;
  content: "\ead4"; }

.icon-watch:before {
  font-size : 20px;
  color: #000000;
  content: "\ead5"; }

.icon-water-off:before {
  font-size : 20px;
  color: #000000;
  content: "\ead6"; }

.icon-water-pump:before {
  font-size : 20px;
  color: #000000;
  content: "\ead7"; }

.icon-water:before {
  font-size : 20px;
  color: #000000;
  content: "\ead8"; }

.icon-weather-cloudy:before {
  font-size : 20px;
  color: #000000;
  content: "\ead9"; }

.icon-weather-fog:before {
  font-size : 20px;
  color: #000000;
  content: "\eada"; }

.icon-weather-hail:before {
  font-size : 20px;
  color: #000000;
  content: "\eadb"; }

.icon-weather-lightning:before {
  font-size : 20px;
  color: #000000;
  content: "\eadc"; }

.icon-weather-night:before {
  font-size : 20px;
  color: #000000;
  content: "\eadd"; }

.icon-weather-partlycloudy:before {
  font-size : 20px;
  color: #000000;
  content: "\eade"; }

.icon-weather-pouring:before {
  font-size : 20px;
  color: #000000;
  content: "\eadf"; }

.icon-weather-rainy:before {
  font-size : 20px;
  color: #000000;
  content: "\eae0"; }

.icon-weather-snowy:before {
  font-size : 20px;
  color: #000000;
  content: "\eae1"; }

.icon-weather-sunny:before {
  font-size : 20px;
  color: #000000;
  content: "\eae2"; }

.icon-weather-sunset-down:before {
  font-size : 20px;
  color: #000000;
  content: "\eae3"; }

.icon-weather-sunset-up:before {
  font-size : 20px;
  color: #000000;
  content: "\eae4"; }

.icon-weather-sunset:before {
  font-size : 20px;
  color: #000000;
  content: "\eae5"; }

.icon-weather-windy-variant:before {
  font-size : 20px;
  color: #000000;
  content: "\eae6"; }

.icon-weather-windy:before {
  font-size : 20px;
  color: #000000;
  content: "\eae7"; }

.icon-web:before {
  font-size : 20px;
  color: #000000;
  content: "\eae8"; }

.icon-webcam:before {
  font-size : 20px;
  color: #000000;
  content: "\eae9"; }

.icon-weight-kilogram:before {
  font-size : 20px;
  color: #000000;
  content: "\eaea"; }

.icon-weight:before {
  font-size : 20px;
  color: #000000;
  content: "\eaeb"; }

.icon-whatsapp:before {
  font-size : 20px;
  color: #000000;
  content: "\eaec"; }

.icon-wheelchair-accessibility:before {
  font-size : 20px;
  color: #000000;
  content: "\eaed"; }

.icon-white-balance-auto:before {
  font-size : 20px;
  color: #000000;
  content: "\eaee"; }

.icon-white-balance-incandescent:before {
  font-size : 20px;
  color: #000000;
  content: "\eaef"; }

.icon-white-balance-irradescent:before {
  font-size : 20px;
  color: #000000;
  content: "\eaf0"; }

.icon-white-balance-sunny:before {
  font-size : 20px;
  color: #000000;
  content: "\eaf1"; }

.icon-wifi:before {
  font-size : 20px;
  color: #000000;
  content: "\eaf2"; }

.icon-wii:before {
  font-size : 20px;
  color: #000000;
  content: "\eaf3"; }

.icon-wikipedia:before {
  font-size : 20px;
  color: #000000;
  content: "\eaf4"; }

.icon-window-close:before {
  font-size : 20px;
  color: #000000;
  content: "\eaf5"; }

.icon-window-closed:before {
  font-size : 20px;
  color: #000000;
  content: "\eaf6"; }

.icon-window-maximize:before {
  font-size : 20px;
  color: #000000;
  content: "\eaf7"; }

.icon-window-minimize:before {
  font-size : 20px;
  color: #000000;
  content: "\eaf8"; }

.icon-window-open:before {
  font-size : 20px;
  color: #000000;
  content: "\eaf9"; }

.icon-window-restore:before {
  font-size : 20px;
  color: #000000;
  content: "\eafa"; }

.icon-windows:before {
  font-size : 20px;
  color: #000000;
  content: "\eafb"; }

.icon-wordpress:before {
  font-size : 20px;
  color: #000000;
  content: "\eafc"; }

.icon-worker:before {
  font-size : 20px;
  color: #000000;
  content: "\eafd"; }

.icon-wunderlist:before {
  font-size : 20px;
  color: #000000;
  content: "\eafe"; }

.icon-xbox-controller-off:before {
  font-size : 20px;
  color: #000000;
  content: "\eaff"; }

.icon-xbox-controller:before {
  font-size : 20px;
  color: #000000;
  content: "\eb00"; }

.icon-xbox:before {
  font-size : 20px;
  color: #000000;
  content: "\eb01"; }

.icon-xda:before {
  font-size : 20px;
  color: #000000;
  content: "\eb02"; }

.icon-xml:before {
  font-size : 20px;
  color: #000000;
  content: "\eb03"; }

.icon-yeast:before {
  font-size : 20px;
  color: #000000;
  content: "\eb04"; }

.icon-yelp:before {
  font-size : 20px;
  color: #000000;
  content: "\eb05"; }

.icon-youtube-play:before {
  font-size : 20px;
  color: #000000;
  content: "\eb06"; }

.icon-youtube:before {
  font-size : 20px;
  color: #000000;
  content: "\eb07"; }

.icon-zip-box:before {
  font-size : 20px;
  color: #000000;
  content: "\eb08"; }

.icon-diffusion-video:before {
  font-size : 20px;
  color: #000000;
  content: "\eb09"; }

.icon-home-on:before {
  font-size : 20px;
  color: #000000;
  content: "\eb0a"; }

.icon-home-off:before {
  font-size : 20px;
  color: #000000;
  content: "\eb0b"; }

.icon-live-custom {
  background-image: url('./assets/diffuser.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  height: 16px;
  width: 16px; 
  margin-left: 2px;
}

.icon-rich-media:before {
  font-size : 20px;
  color: #000000;
  content: "\eb0d"; }

.icon-download-on:before {
  font-size : 20px;
  color: #000000;
  content: "\eb0e"; }

.icon-download-off:before {
  font-size : 20px;
  color: #000000;
  content: "\eb0f"; }

.icon-dailymotion-custom:before {
  font-size : 20px;
  color: #000000;
  content: "\eb10"; }

.icon-pearl-custom:before {
  font-size : 20px;
  color: #000000;
  content: "\eb11"; }

.icon-security-on:before {
  font-size : 20px;
  color: #000000;
  content: "\eb12"; }

.icon-security-off:before {
  font-size : 20px;
  color: #000000;
  content: "\eb13"; }

.icon-audio-description-on:before {
  font-size : 20px;
  color: #000000;
  content: "\eb14"; }

.icon-audio-description-off:before {
  font-size : 20px;
  color: #000000;
  content: "\eb15"; }

.icon-rmbox-custom:before {
  font-size : 20px;
  color: #000000;
  content: "\eb16"; }

.icon-classement-custom {
  background-image: url('./assets/sort.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  height: 16px;
  width: 16px; 
  margin-left: 2px;
  }

  .icon-classement-customwhite {
    background-image: url('./assets/ranking (1).svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    height: 16px;
    width: 16px; 
    margin-left: 2px;
    
  }
  

.icon-upload-custom:before {
  font-size : 20px;
  color: #000000;
  content: "\eb18"; }

.icon-link-custom:before {
  font-size : 20px;
  color: #000000;
  content: "\eb19"; }

.icon-waiting-custom:before {
  font-size : 20px;
  color: #000000;
  content: "\eb1a"; }

.icon-webcam-custom:before {
  font-size : 20px;
  color: #000000;
  content: "\eb1b"; }

.icon-presentation-custom:before {
  font-size : 20px;
  color: #000000;
  content: "\eb1c"; }

.icon-storyboard-custom:before {
  font-size : 20px;
  color: #000000;
  content: "\eb1d"; }

.icon-meta-custom {
  background-image: url('./assets/metadonee.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  height: 16px;
  width: 16px; 
  margin-left: 2px;
}

.icon-all-custom:before {
  font-size : 20px;
  color: #000000;
  content: "\eb1f"; }

.icon-share-custom:before {
  font-size : 20px;
  color: #000000;
  content: "\eb20"; }

.icon-no-image-custom:before {
  font-size : 20px;
  color: #000000;
  content: "\eb21"; }

.icon-question-custom:before {
  font-size : 20px;
  color: #000000;
  content: "\eb22"; }

.icon-settings-custom:before {
  font-size : 20px;
  color: #000000;
  content: "\eb23"; }

.icon-quizz-custom:before {
  font-size : 20px;
  color: #000000;
  content: "\eb24"; }

.icon-ad-on-custom:before {
  font-size : 20px;
  color: #000000;
  content: "\eb25"; }

.icon-ad-off-custom:before {
  font-size : 20px;
  color: #000000;
  content: "\eb26"; }

.icon-custom-transcription-auto:before {
  font-size : 20px;
  color: #000000;
  content: "\eb27"; }

.icon-custom-transcription-reload:before {
  font-size : 20px;
  color: #000000;
  content: "\eb28"; }

.icon-custom-transcription:before {
  font-size : 20px;
  color: #000000;
  content: "\eb29"; }

.icon-custom-transcription-dis:before {
  font-size : 20px;
  color: #000000;
  content: "\eb2a"; }

.icon-video-server:before {
  font-size : 20px;
  color: #000000;
  content: "\eb2b"; }

.icon-add:before {
  font-size : 20px;
  color: #000000;
  content: "\eb2c"; }

.icon-add-video:before {
  font-size : 20px;
  color: #000000;
  content: "\eb2d"; }

.icon-audience:before {
  font-size : 20px;
  color: #000000;
  content: "\eb2e"; }

.icon-compte:before {
  font-size : 20px;
  color: #000000;
  content: "\eb2f"; }

.icon-dashboard:before {
  font-size : 20px;
  background-image: url('./assets/appstore.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  height: 16px;
  width: 16px; 
  margin-left: 2px;
}

.icon-front-office:before {
  font-size : 20px;
  color: #000000;
  content: "\eb31"; }

.icon-live:before {
  font-size : 20px;
  color: #000000;
  content: "\eb32"; }

.icon-media:before {
  font-size : 20px;
  color: #000000;
  content: "\eb33"; }

.icon-parametres:before {
  font-size : 20px;
  color: #000000;
  content: "\eb34"; }

.icon-pwd:before {
  font-size : 20px;
  color: #000000;
  content: "\eb35"; }

.icon-quotas:before {
  font-size : 20px;
  color: #000000;
  content: "\eb36"; }

.icon-start-live:before {
  font-size : 20px;
  color: #000000;
  content: "\eb37"; }

.icon-check1:before {
  font-size : 20px;
  color: #000000;
  content: "\eb38"; }

.icon-close1:before {
  font-size : 20px;
  color: #000000;
  content: "\eb39"; }

.icon-gear:before {
  font-size : 20px;
  color: #000000;
  content: "\eb3a"; }

.icon-help1:before {
  font-size : 20px;
  color: #000000;
  content: "\eb3b"; }

.icon-key1:before {
  font-size : 20px;
  color: #000000;
  content: "\eb3c"; }

.icon-logout1:before {
  font-size : 20px;
  color: #000000;
  content: "\eb3d"; }

.icon-plus1:before {
  font-size : 20px;
  color: #000000;
  content: "\eb3e"; }

.icon-share1:before {
  font-size : 20px;
  color: #000000;
  content: "\eb3f"; }

.icon-user:before {
  font-size : 20px;
  color: #000000;
  content: "\eb40"; }

.icon-calendar-new:before {
  font-size : 20px;
  color: #000000;
  content: "\eb41"; }

.icon-chartv2:before {
  font-size : 20px;
  color: #000000;
  content: "\eb42"; }

.icon-brushv2:before {
  font-size : 20px;
  color: #000000;
  content: "\eb43"; }

.icon-livev2:before {
  font-size : 20px;
  color: #000000;
  content: "\eb44"; }

.icon-mailv2:before {
  font-size : 20px;
  color: #000000;
  content: "\eb45"; }

.icon-dashboardv2:before {
  font-size : 20px;
  color: #000000;
  content: "\eb46"; }

.icon-videov2:before {
  font-size : 20px;
  color: #000000;
  content: "\eb47"; }

.icon-noun_live:before {
  font-size : 20px;
  color: #000000;
  content: "\eb48"; }

.icon-web-skin:before {
  font-size : 20px;
  color: #000000;
  content: "\eb49"; }

.icon-live-skin:before {
  font-size : 20px;
  color: #000000;
  content: "\eb4a"; }

.icon-Factures:before {
  font-size : 20px;
  color: #000000;
  content: "\eb4b"; }

.icon-abonnement:before {
  font-size : 20px;
  color: #000000;
  content: "\eb4c"; }

.fillable{
  position: relative;
  background-color: #ffffff;
  width: 100%;

}
.Blue-Title{
  font-weight: 600;
  font-size: 20px;
  line-height: 26.68px;
  color: var(--color-theme);
  // background-color: black;
  // padding: 1rem;
}
.childContent{
  position: relative;
  padding: 10px;
  background-color: white;
  outline: solid 12px #eef2f4;
  outline-offset: -12px;
  // width: calc(100vw - 272px);
  // height: calc(100vh - 84px);
  overflow-y: scroll;
  width: 100%;
}

.hiddenVanPlace{
  height: 100vh;
  padding-top: 1rem;
}
// .smalleeer{
//   width: calc(100vw - 122px) !important;
// }
.Sider {
  display: block;
}
@media only screen and (max-width: 768px) {
  .Sider {
    display: none;
  }
}

.drawer {
  display: none;
}
@media only screen and (max-width: 768px) {
  .drawer {
    display: block;
  }
}

.cardIframe{
  height: 98%;
.container {
  position: relative;
  width: 100%;
  padding-bottom: 53%;
  padding-top: 30px;
  height: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch; /*<<--- THIS IS THE KEY*/
  //padding-top: 56.25%; /* 16:9 Aspect Ratio */
  @media screen  and (max-width: 768px){
    padding-bottom: 125% !important;
  }
  @media screen  and (max-width: 767px){
    padding-bottom: 175% !important;
  }

  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}
}

::-webkit-scrollbar {
  width: 0.2em;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  background-color: #000000;
}

/* Hide scrollbar for Firefox */
* {
  scrollbar-width: none;
  scrollbar-color: #000000 #F5F5F5;
}

*::-webkit-scrollbar {
  display: none;
}

body{
  max-height: 100vh;
}

// .darkSpaceOptions{
//   background-color: black;
//   opacity: 0.2;
//   position: absolute;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   right: 0;
//   width: 100vw;
//   height: 100vh;
// }