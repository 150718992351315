
.sidebar{
    /* height: calc(100vh - 70px); */
    max-height: calc(100vh - 100px);
    overflow-y: scroll;
}
  .sidebar-closed {
    width: 30px !important;
  }
  .logo_burger{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  @media screen and (max-width: 650px) {
    .mobileee {
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      width: 100% !important;
      height: 100% !important;
      z-index: 5000 !important;
    }
  }
  .burger-icon {
    width: 80px;
    height: 50px;
    background-color: #ffffff;
    border-radius: 50px;
    position: relative;
    transform: rotate(-45deg);
  }
  
  .burger-icon::before,
  .burger-icon::after {
    content: "";
    display: block;
    position: absolute;
    width: 20px;
    height: 2px;
    background-color: #ffffff;
    color: #ffffff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .burger-icon::before {
    transform: rotate(45deg);
  }
  
  .burger-icon::after {
    transform: rotate(-45deg);
    left: auto;
    right: 10px;
  }

  i{
    opacity: 75%;
  }

  .blackerr{
    opacity: 200% !important;
    position: relative;
  }
  .blackerr::before {
    content: "";
    position: absolute;
    bottom: -7px;
    width: 100%;
    height: 4px;
    left: 50%;
    transform: skew(-12deg) translateX(-50%);
    background: black;
    z-index: -1;
  }
  .ggggg{
    font-size: 1;
  }

  .bolderModule{
    font-weight: 600 !important;
  }