.Connexion{
  background-color: RGB(240, 243, 247);
  width: 100vw;
  height: 100vh;
  position: relative;
  .formConnexion{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50% , -50%);
    .submit_button{
      width: 100%;
    }
    .rememberMeDiv{
      display: flex;
      flex-direction: row;
      justify-content: space-between !important;
      align-items: center !important;
      margin-bottom: 25px;
      .rememberMeDivCol2{
        
          color: RGB(24, 144, 255);
          cursor: pointer;
        
      }
    }
    .LogoConnexionDiv{
      margin-bottom: 50px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      img{
        max-width: 100%;
        height: 73px;
      }
    }
    .TitleConnexionDiv{
      margin-bottom: 50px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      
      span{
        font-size: 20px;
        color: RGBA(0, 0, 0, 0.85);
        font-weight: 500;
      }
    }
    .icon-Login-Dashboard{
      margin-right: 0.5rem;
      color: black;
    }
    .Hide-Login-Dashboard{
      cursor: pointer;
    }
    @media screen and (max-width: 767px) {
      width: 80%;
      .LogoConnexionDiv{
        img{
          max-width: 70%;
          height: 60px;
        }
      }
    }
    @media screen and (min-width: 768px) and (max-width: 1300px) {
      width: 50%;
      .LogoConnexionDiv{
        img{
          max-width: 70%;
          height: 60px;
        }
      }
    }
  }
  .backgroundDiv{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    width: 100%;
    height: 100%;
    .icon-Group{
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50% , -50%);
    }
  }
}
.error{
  color: rgb(219, 28, 28);
}

.underLoginButton{
  margin-top: 0.5rem;
}
.underLoginButton , .copyRight{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.anotherLinkEmpreient{
  cursor: pointer;
}

.copyRight , .anotherLinkEmpreient{
  font-size: 12px;
  color: RGBA(0, 0, 0, 0.85);
  font-weight: 500;
}

