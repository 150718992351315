.bg-white-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    gap: 1rem;
    position: relative;
    width: 50%;
    height: 50%;
    padding: 4% 8%;
}
.send-button{
    width: 100%;
    height: 40px;
    color: #fff;
    border-radius: 4px;
    line-height: 24px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.4;
    cursor: pointer;
    border: none;
}

.adjustDouble{
    margin: 2rem 0rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 80%;
    height: 55px;
    position: relative;
}

.double-check-first{
    color: #182035;
    font-size: 25px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    text-align: center;

}
.double-check-second{
    color: #606268;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;

}

.fffff{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100vw;
}

.LogoDoubleDiv {
    margin-bottom: 50px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      img{
        max-width: 100%;
        height: 73px;
      }
}

@media screen and(min-width: 768px) and (max-width: 1300px) {
    .LogoDoubleDiv{
      img{
        max-width: 70%;
        height: 60px;
      }
    }
    .bg-white-container{
        width: 60%;
    }
    .makeStyles-inputBox-1{
        width: 20%;
    }
  }
  @media screen and (max-width: 767px) {
    .LogoDoubleDiv{
      img{
        max-width: 70%;
        height: 60px;
      }
    }
    .bg-white-container{
        width: 80%;
    }
    .makeStyles-inputBox-1{
        width: 20%;
    }
  }
  @media screen and (max-width: 550px) {
    .LogoDoubleDiv{
      img{
        max-width: 70%;
        height: 60px;
      }
    }
    .bg-white-container{
        width: 80%;
    }
    .adjustDouble{
        width: 100%;
    }
  }

  .disableCOde{
    background-color: rgba(0, 0, 0, 0.26) !important;
    opacity: 0.5; 
    cursor: not-allowed; 
    pointer-events: none;
    color: black;
  }

  .top-right{
    position: absolute;
    top: 10px;
    right: 10px;
  }