.titlesOfP {
    margin-bottom: 0;
    font-family: Roboto;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: "#6C737F";
    display: flex;
    align-items: center;
    justify-content: center;
}
/* .userImage{
  height: 12px;
  width: 12px;
} */
.navbar-container {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 64px;
    
  }
  .secondeLayoutModif{
    margin-left: -250px;
  }
  .secondeLayoutModifUndo{
    margin-left: -90px;
  }
  .gg{
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
  }
  
  .right-navBar{
    display: flex;
    /* gap: 1rem; */
    margin-left: auto;
  }
  .toolbar {
    display: flex;
    justify-content: space-between;
    z-index: 999999;
  }
  
  .flag-selector {
    display: flex;
    align-items: center;
  }
  
  .flag-selector img {
    width: 30px;
    margin-right: 10px;
  }
  
  .user-dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 10px;
    z-index: 999;
  }
  
  .language-dropdown {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 10px;
    z-index: 999;


  }
  .select-languagee{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    gap: 0.5rem;

  }
  .Languagee{
    font-family: Roboto;
    font-size: large;
    font-weight: 500;
  }
  
  .language-dropdown .MuiSvgIcon-root {
    margin-right: 5px;
  }
  
  .language-dropdown .MuiMenu-paper {
    width: 150px;
  }
  
  .user-dropdown .MuiAvatar-root {
    margin-right: 10px;
  }
  
  .user-dropdown .MuiMenu-paper {
    width: 150px !important;
  }
  
  .user-dropdown .MuiMenuItem-root {
    display: flex;
    align-items: center;
  }
  
  .user-dropdown .MuiMenuItem-root span {
    margin-left: 10px;
  }
  
 
  
  @media (min-width: 768px) {
    .navbar-container {
      flex-direction: row;
    }
  }

  .divider{
    margin: .5rem 0rem !important;
  }