.Profile_Container{
    width: 100%;
    height: 100%;
    /* border: 2px red solid;
    padding: 0.5rem; */
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px; 

}
@media (max-width: 1450px) {
    .Profile_Container {
        display: flex;
        flex-direction: column-reverse;
        overflow-y: scroll;
    }
    .card1_2 {
        min-height: -webkit-fill-available !important;
    }
  }

  @media (max-width: 650px) {
    .firstPartProfileInputs {
        display: flex !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        & div{
            width: 100%;
        }
    }
    .ProfileActionsButtons{
        width: 60% !important;
    }
    .ProfileDataAndActions{
        margin-bottom: 8rem !important;
    }
    .UploadImageProfile{
        width: 90% !important;
        margin-inline: 5% !important;
    }
  }


.card1_2{
    /* border: 2px orange solid; */
    grid-area: 1 / 1 / 7 / 3;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.card1{ 
    height: 50%;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    position: relative;
} 
.card2{ 
    height: 50%;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
} 
.card3{
    border: 1px solid #E0E0E0;
    grid-area: 1 / 3 / 7 / 7;
    border-radius: 8px;
    min-height: 100%;
    position: relative;
    /* overflow-y: scroll; */
}

.ProfileCardTitle{
    font-family: Inter;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 0.6rem 1.2rem;

}
.underLineHeaderProfile{
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #E0E0E0;
    margin: 1em 0;
    padding: 0;

}

.ProfileActionsAndIconAndUpload {
    height: 75%;
    width: 100%;
    position: relative;
    left: 0;
    right: 0;
}
.ProfileDataAndActions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    margin-left: 1rem;
}
.ProfileStatsAndPurchase{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    margin-inline: 1.5rem;
}
.ProfileData{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.4rem;
}
.modifierPhotoDeProfile{
    font-family: Inter;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}
.actionProfile{
    display: flex;
    gap: 1rem;
}

.suppProfile , .modifProfile{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    font-family: Inter;
    cursor: pointer;
}
.Supp{
    color: #637381;
}
.Modif{
    color: #1890FF;
}
.UploadImageProfile{
    position: absolute;
    width: 80%;
    height: 55%;
    /* padding: 1rem; */
   margin-inline: 10%;
    border-radius: 6px;
    background-color: #F4F7FF;
    cursor: cell;
    border: 2px dashed #3056D3;
    bottom: 1rem;
}
.animate{
    animation: dovdov 2s infinite ease-in-out;
}

.UploadImageProfile input[type="file"] {
    position: absolute;
    opacity: 0;
    height: 100%;
    width: 100%;
    cursor: cell;
    top: 0;
    left: 0;
}

.textUpload{
    cursor: cell;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-self: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
}

@keyframes dovdov {
    0% {
      transform: scale(0.98);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.98);
    }
  }

  .UploadImageProfile > img{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

}
.UploadImageProfile > .cancelUploadingThis{
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%,-50%);
    color: #637381;
    z-index: 99;
    cursor: pointer;
    font-size: xx-large;
    width: 15%;
    height: 30%;
}

.notSoClear{
    color: #637381;
}

.circlePath{
    // width: 40%;
    // height: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.CircularProgressbar-text{
    transform: translate(-10%,3%);
}

.no{
    cursor: not-allowed;
}
.buttonToPurchase{
    background-color:#13C2AD ;
    color: white;
    border: 4px;
    width: 120px;
    height: 30px;
    cursor: pointer;
}

.firstPartProfileInputs{
    display: grid;
    grid-template-columns: 1fr 1fr ;
    grid-template-rows: 1fr 1fr ;
    row-gap: 1rem;
    column-gap: 3rem;
    margin-bottom: 1rem;
}

.theGapForSpecialTitles{
    margin-bottom: 1rem;
    font-family: Inter;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    font-style: normal;
}

.dataChangeProfile{
    margin: 1rem;
    height: calc(100% - 100px);
    position: absolute;
    overflow-y: scroll;
    width: calc(100% - 2rem);
}

#Addresse{
    position: relative;
}

.ProfileActionsButtons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    gap: 6%;
    width: 40%;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    float: right;
}


.ProfileActionsButtons > .save{
    background-color: #1976D2;
    color: white;
}
.ProfileActionsButtons > .dontSaveAndGoBack{
    background-color: white;
    color: #1976D2;
}


.ProfileActionsButtons > .dontUpdate{
    background-color: #B5BCC4;
    cursor: not-allowed;
}

.ProfileActionsButtons > .dontUpdate:hover{
    background-color: #B5BCC4;
}

.requiredStar{
    color: red;
    
}