.ApiKeySpace{
  width: 98.5%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  overflow-y: scroll;
  height: auto ;
  border: 1px solid #E0E0E0;
  padding: 2rem;
  position: absolute;
}
.marginBetweenKeyAndToken{
    display: flex;
    gap: 2rem;
}
.tokenText{
    font-size: 16px;
    line-height: 1.5;
    max-width: 80%;
    overflow-wrap: break-word;
  }


  .copy-message {
    position: absolute;
    z-index: 1;
    padding: 4px;
    background-color: #333;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
  }