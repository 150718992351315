.BorderLayoutOptions{
    border: 1px solid #E0E0E0;
    padding: 0.4rem 0.7rem;
}
.Layout-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.1rem;
    margin-bottom: 1.1rem;
}

@media only screen and (max-width: 700px) {
    .Layout-content {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
  }

.change-layout-button{
    width: 178px;
    height: 40px;
    color: #fff;
    border-radius: 4px;
    line-height: 24px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.4;
    margin-right: 1rem;
    cursor: pointer;
    border: none;
}